.notification-admin-header {
  padding-bottom: 3em;
  border-bottom: 1px solid #f1f1f1;
  &-right {
    &-readAll {
      cursor: pointer;
      margin-right: 2em;
      position: relative;
      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: black;
        transition-property: color, background-color, border-color, text-decoration-color, fill,
          stroke, opacity, box-shadow, transform, filter, backdrop-filter;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
        transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
        transform-origin: right;
        transition-duration: 200ms;
        content: '';
        transform: scaleX(0);
      }
      &:hover {
        &::before {
          transform-origin: left;
          transform: scaleX(1);
        }
      }
    }
    text-align: right;
    .ant-btn-primary {
      background-color: rgba(248, 248, 250, 1);
      color: black;
      border: none;
    }
    .ant-btn-primary:hover {
      background-color: rgb(227, 227, 230);
      color: black;
      border: none;
    }
  }
  .ant-btn {
    border-radius: 0px !important;
    height: 40px !important;
  }
}
