.gallery-experience-page {
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: none;
  }
  .admin-form .ant-btn {
    padding: 12px 32px !important;
  }
  &__grid {
    display: flex;
    gap: 40px;
    width: 100%;
    @media screen and (max-width: 880px) {
      display: block;
    }
  }
  &__form {
    width: 100%;
    @media screen and (max-width: 880px) {
      margin-top: 20px;
    }
  }
  &__image {
    @media screen and (max-width: 880px) {
      justify-content: center;
    }
    &__overflow {
      @media screen and (max-width: 600px) {
        justify-content: start;
        overflow-x: auto;
        padding: 20px;
      }
    }
    order: 9999;
    display: flex;
    justify-content: right;
    font-family: 'Satoshi', sans-serif;
    &__wrapper {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
      padding: 24px;
      height: fit-content;
      &__section {
        &__description {
          display: flex;
          justify-content: center;
          text-align: center;
          align-items: center;
          margin-bottom: 12px;
          width: 100%;
          &__logo {
            margin-bottom: 8px;
          }
          &__heading {
            font-weight: 900;
            font-size: 20px;
            line-height: 130%;
            letter-spacing: -0.02em;
          }
          &__description {
            font-weight: 400;
            font-size: 12px;
            line-height: 170%;
            margin-top: 12px;
            letter-spacing: -0.02em;
          }
          &__bottom {
            margin-top: 8px;
          }
        }
        &__image {
          border: 0.782348px solid #e4e4e4;
          border-radius: 6.25878px;
          padding: 15px;
          text-align: center;
          width: 100%;
          &__text {
            font-weight: 700;
            font-size: 12px;
            line-height: 170%;
            letter-spacing: -0.02em;
            margin-bottom: 15px;
          }
          &__image {
            img {
              height: 234px;
              width: 234px;
            }
          }
        }
        &__bottom {
          display: flex;
          justify-content: center;
          margin-top: 12px;
        }
        &__order-1 {
          order: -1;
        }
      }
    }
  }
}
