.main {
  position: relative;
  overflow: hidden;
}

.landing,
.single-page {
  background: @dark;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &__modal {
    z-index: 9999;
    &__icon {
      margin-bottom: 1rem;

      svg {
        width: 4.25rem;
        height: 4.25rem;
        color: @green;
      }
    }

    .ant-modal-body {
      background: @darkSecondary !important;
      padding: 3.75rem 4.5rem;
      text-align: center;

      .landing-h2 {
        margin-bottom: 1.25rem !important;
      }

      .landing-paragraph {
        margin-bottom: 1.875rem !important;
        &__contact {
          display: flex;
          justify-content: center;
          @media only screen and (max-width: @screen-xl) {
            justify-content: space-between;
            flex-direction: column;
          }
          & > div {
            margin: 0 15px;
            text-align: center;
            @media only screen and (max-width: @screen-xl) {
              margin-bottom: 30px;
            }
            strong {
              color: #fff;
            }
          }
        }
      }

      input,
      textarea {
        border: none !important;
        padding: 0.875rem 1.875rem;
        background: #4a4a4a !important;
        font-family: @montserrat;
        font-size: 1rem;
        color: rgba(255, 255, 255, 0.5);

        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }
      }

      .ant-form-item-explain-error {
        text-align: left;
      }
    }
  }

  &__blur {
    position: absolute;
    z-index: -1;

    &-browser {
      top: 0px;
      right: -1000px;
      z-index: 1;
    }

    &-creator {
      top: -45%;
      left: -950px;
    }

    &-platform {
      bottom: -85%;
      right: -950px;
    }

    &-footer {
      top: -300px;
      z-index: 1;
      left: -300px;
    }
  }

  .header {
    padding: 0 0 31.25rem;
    background: url('../../assets/img/bg-header.png');
    background-size: cover;
    background-repeat: no-repeat;

    .hero {
      margin: 11.25rem auto 0;
      max-width: 45rem;
      width: 95%;
      text-align: center;

      &__button {
        &__register {
          margin-right: 1rem;
        }
      }

      .landing-paragraph {
        color: @white !important;
      }
    }
  }

  .container {
    margin: auto;
    width: 100%;
    max-width: 1200px;
  }

  section {
    padding: 6.25rem 0;
    position: relative;
  }

  .row-reverse {
    flex-direction: row-reverse;
  }

  &__section {
    &-browser {
      margin-top: -16.8rem;
      padding: 0 0 6.25rem !important;

      &__wrapper {
        margin: 0 auto;
        border-radius: 0.7rem;
        width: 100%;
        max-width: 1080px;
        background-repeat: no-repeat;
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.7), 0px 20px 30px rgba(0, 0, 0, 0.3),
          0px 10px 50px rgba(0, 0, 0, 0.2);

        img {
          width: 100%;
        }
      }

      &__scroller {
        height: 600px;
        overflow-y: scroll;
        border-bottom-left-radius: 0.7rem;
        border-bottom-right-radius: 0.7rem;

        &::-webkit-scrollbar {
          width: 3px;
          height: 2px;
          background: @dark;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #666;

          &:hover {
            background: #888;
          }
        }

        img {
          width: 100%;
        }
      }
    }

    &-cta {
      width: 100%;
      background: url('../../assets/img/bg-cta.png');
      padding: 6.25rem 2rem !important;

      &__wrapper {
        margin: 0 auto;
        max-width: 48rem;
        text-align: center;
      }
    }

    &-creator {
      z-index: 20;

      &__image {
        margin-right: -11rem !important;
      }
    }

    &-platform {
      z-index: 20;
      overflow: hidden;
      padding-bottom: 12.5rem !important;

      &__image {
        margin-left: -11rem !important;
      }
    }
  }

  &-h2,
  &-h1 {
    color: @white !important;
    font-family: @montserrat;
    font-weight: 700 !important;
  }

  &-h2 {
    font-size: 2.25rem !important;
    line-height: 3.125rem !important;
    margin-bottom: 1.875rem !important;
  }

  &-h1 {
    font-size: 3rem !important;
    line-height: 4rem !important;
    margin-bottom: 1.25rem !important;
  }

  &-paragraph {
    margin-bottom: 2.875rem !important;
    line-height: 2rem;
    font-size: 1.125rem;
    font-family: @inter;
    color: rgba(255, 255, 255, 0.8);

    &-register {
      width: 100%;
      margin: auto;
      max-width: 33rem;
    }
  }

  &-title {
    &-center {
      text-align: center;
    }
  }
}

.landing,
.auth {
  &__button {
    border-radius: 0.625rem;
    line-height: 1rem;
    height: auto !important;
    transition: 0.4s;
    padding: 1rem 1.5rem !important;

    &-outline {
      border-color: @white;
      color: @white;
      background-color: transparent;

      &:hover {
        color: @dark !important;
        background-color: @white !important;
      }
    }

    span {
      font-family: @montserrat;
      font-size: 1rem;
    }

    &:hover {
      border-color: @white;
      color: @white;
      background-color: transparent;
      transition: 0.4s;
    }
  }
}

.single-page {
  position: relative;

  &-wrapper {
    z-index: 9;
  }

  &-container {
    padding: 6.25rem 0 3rem;
    position: relative;

    .container {
      position: relative;
      z-index: 99;
    }
  }

  &-first-blur {
    position: absolute;
    top: -200px;
    left: -1000px;
    z-index: 0;
  }

  &-second-blur {
    position: absolute;
    bottom: -300px;
    right: -1000px;
    z-index: 0;
  }

  &-footer-blur {
    position: absolute;
    bottom: -1000px;
    left: -300px;
    z-index: 0;
  }
}

.faq {
  &-title {
    text-transform: capitalize;
  }

  &-right {
    padding-left: 2rem;
  }

  &-menu {
    border: 1px solid #4a4a4a;
    padding: 1.875rem;
    text-transform: capitalize;

    &__item {
      font-family: @montserrat;
      color: @white;
      font-size: 1.125rem;
      line-height: 1.375rem;
      font-weight: 600;
      cursor: pointer;

      &:not(:last-child) {
        margin-bottom: 1rem;
        border-bottom: 1px solid rgba(74, 74, 74, 0.3);
        padding-bottom: 1rem;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .landing {
    &__blur {
      display: none;
    }

    .container {
      padding: 0px 2rem;
    }

    &__section {
      &-creator,
      &-platform {
        &__image {
          margin: 0px 0px 1rem !important;
          padding: 0px 2rem;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: @screen-md) {
  .landing {
    .header {
      padding-bottom: 8rem;

      .hero {
        margin-top: 100px;
      }
    }

    section {
      padding: 3.25rem 0;
    }

    &-paragraph {
      margin-bottom: 1rem !important;
      line-height: 1.5rem;
      font-size: 1rem;
      font-family: @inter;
      color: rgba(255, 255, 255, 0.8);
    }

    &-h2 {
      font-size: 1.25rem !important;
      line-height: 1.7rem !important;
      margin-bottom: 1rem !important;
    }

    &-h1 {
      font-size: 1.8rem !important;
      line-height: 2.4rem !important;
      margin-bottom: 1rem !important;
    }

    &__section {
      &-creator,
      &-platform {
        padding-bottom: 3.25rem !important;

        &__image {
          margin: 0px 0px 1rem !important;
          padding: 0px;

          img {
            width: 100%;
          }
        }
      }

      &-browser {
        margin: -3rem auto 0px;
        padding-bottom: 3.25rem !important;
        width: 90%;
      }

      &-cta {
        margin-top: 3.25rem;
        padding: 3.25rem 2rem !important;
      }
    }

    &__button {
      padding: 0.8rem 1.5rem;

      span {
        font-size: 0.8rem !important;
      }
    }

    &__modal {
      .ant-modal-body {
        padding: 2rem 2.25rem;

        .landing-h2,
        .landing-paragraph {
          margin-bottom: 0.8rem !important;
        }

        input {
          padding: 0.8rem 1rem;
          font-size: 0.9rem;
        }
      }
    }
  }

  .single-page-container {
    padding: 3.25rem 0 3rem;
  }

  .faq {
    &-left {
      display: none;
    }

    &-right {
      padding-left: 0px;
    }
  }
}
