.vip-card {
  overflow: hidden;
  position: relative;
  aspect-ratio: 1/1;
  &__bg {
    svg {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
  &__content {
    margin: 0 auto;
    width: 70%;
    position: absolute;
    padding-top: 3%;
    left: 0;
    right: 0;
    &__image {
      display: block;
      width: 100%;
      &-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        border-radius: 0;
        z-index: 2;
        background: #f8f8fa;
        box-shadow: 0 4px 24px rgb(0 0 0 / 10%);
        aspect-ratio: 1;
        text-align: center;
        color: rgba(26, 26, 26, 0.5);
        .anticon {
          margin-bottom: 1rem;
          font-size: 4rem;
          color: rgba(26, 26, 26, 0.5);
          display: block;
        }
      }
    }
    &__title {
      text-align: center;
      font-family: Avenir Next Condensed, sans-serif;
      font-weight: 600;
      text-transform: uppercase;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin-top: 10%;
      font-size: 24px;
      line-height: 32px;

      background: linear-gradient(199.27deg, #d8b455 25.91%, #ffe9af 55.27%, #d8b455 84.63%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      @media screen and (min-width: @screen-sm) {
        margin-top: 10%;
        font-size: 34px;
        line-height: 44px;
      }
      @media screen and (min-width: @screen-lg) {
        margin-top: 9%;
        font-size: 24px;
        line-height: 32px;
      }
    }
    &__badge {
      margin: 0 auto;
      text-align: center;
      svg {
        width: 90%;
      }
    }
  }
}
