.user-activities {
  margin-top: 30px;
  &-header {
    &-right {
      text-align: right;
      .ant-btn-primary {
        background-color: rgba(248, 248, 250, 1);
        color: black;
        border: none;
      }
      .ant-btn-primary:hover {
        background-color: rgb(227, 227, 230);
        color: black;
        border: none;
      }
    }
  }
  &-table {
    margin-top: 18px;
    .avatar-name {
      padding-left: @gap-sm;
    }
  }
  .ant-input {
    border-radius: 0px;
    border-top: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    border-right: none;
  }
  .ant-input:focus {
    border-radius: 0px;
    border-top: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
  }
  .ant-input:hover {
    border-radius: 0px;
    border-top: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
  }
  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border-radius: 0px;
    border-top: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
  }
}
