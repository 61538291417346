.single-page-content {
  color: @white;
  font-family: @montserrat;
  font-size: 1.125rem;
  line-height: 2rem;
  color: rgba(255, 255, 255, 0.8) !important;

  a {
    color: #fff;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  li {
    p {
      margin-bottom: 0.5rem;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    color: @white;
    font-family: @montserrat;
    font-weight: 700 !important;
    margin-bottom: 1.25rem;
  }

  h1 {
    font-size: 3rem;
    line-height: 4rem;
  }

  h2 {
    font-size: 2.25rem;
    line-height: 3.125rem;
  }

  h3 {
    font-size: 1.875rem;
    line-height: 3.125rem;
  }
}

@media screen and (max-width: @screen-md) {
  .single-page-content {
    font-size: 1rem;
    line-height: 1.8rem;
    h1 {
      font-size: 1.8rem;
      line-height: 2.5rem;
    }

    h2 {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    h3 {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }
}
