.collectible-card {
  &__image {
    position: relative;
    border-radius: @border-radius-base;
    z-index: 2;
    background: @light-accent-lighter;
    margin-bottom: @gap-md;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
    aspect-ratio: 1;
    .ant-image {
      display: block;
    }
    img {
      border-radius: @border-radius-base;
      aspect-ratio: 1;
      object-fit: cover;
      width: 100%;
    }
    &__player {
      position: absolute;
      top: 0;
      z-index: 1; /* matters! */
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #0000008f 100%);
      border-radius: 5px;
      width: 100%;
      height: 100%;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.842) 0%, rgba(0, 212, 255, 0) 100%);
        z-index: -1;
        opacity: 0;
        transition: all 0.4s;
        border-radius: 5px;
      }
      &:hover::before {
        opacity: 1;
      }
    }
  }
  &__placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    &__content {
      text-align: center;
      color: @dark-50;
      .anticon {
        margin-bottom: @gap-md;
        font-size: 4rem;
        color: @dark-50;
      }
    }
  }
  &__detail {
    position: relative;
    border-radius: @border-radius-base;
    padding: @gap-md;
    z-index: 0;
    background: @light-accent-lighter;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
    &__title {
      font-size: 1.2rem !important;
      font-weight: 400 !important;
    }
    &__url {
      font-size: 0.9rem;
      color: @dark-50;
    }
  }
}
