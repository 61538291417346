.admin-form {
  &-collectibles {
    &-button {
      margin-top: 20px;
    }
  }
  .preview-sales {
    margin-top: @gap-md;
    padding: @gap-md @gap-md 0 @gap-md;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
    background: @light-accent-lighter;
    h3 {
      margin-bottom: @gap-md;
      font-size: @font-body-lg;
      font-weight: 400;
    }
    &__list {
      color: @dark-50;
      &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid @light-accent;
        padding: @gap-sm 0;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
  .edit-collectible {
    .ant-tabs-tab.ant-tabs-tab-disabled {
      display: none;
    }
  }
  .ant-upload-list-item-info {
    .ant-btn-icon-only > .anticon.anticon-delete {
      display: block;
      transform: translateY(-40%);
    }
  }
  .collectible-form {
    &-title {
      margin-bottom: 17px !important;
      line-height: 29.05px;
      .ant-form-item-control-input {
        min-height: auto !important;
      }
      .ant-input {
        padding: 0 !important;
        font-size: 1.5rem;
        font-weight: 700;
      }
    }
    &-slug {
      line-height: 13.03px;
      .ant-form-item-control-input {
        min-height: auto !important;
      }
      .ant-input,
      span.form-slug-label {
        padding: 0 !important;
        font-size: 0.75rem;
        color: #1a1a1a80;
      }
    }
    &-tabs {
      .ant-tabs-nav {
        margin-bottom: 30px;
      }
      .admin-form__group__heading {
        border-bottom: none;
        font-size: 1rem;
        font-weight: 700;
        padding-bottom: 0;
        margin-bottom: 0.75rem;
      }
      .admin-form__group__description {
        font-size: 12px;
        color: rgba(26, 26, 26, 0.5);
      }
      .ant-form-item-extra {
        font-size: 12px;
      }
      .admin-form__group {
        margin-bottom: 0;
      }
      @media screen and (min-width: 1200px) {
        .ant-tabs-nav-list {
          width: 99.9%;
        }
      }

      .ant-tabs-tab {
        flex: 1;
        div.ant-tabs-tab-btn {
          margin-inline: auto;
        }
      }
    }
  }
}

.faq {
  width: 100%;
  ul {
    padding-left: 0;
  }
  &-collapse {
    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
      padding-right: 22px !important;
    }
    .ant-collapse > .ant-collapse-item {
      border-bottom: 0px !important;
    }
  }
  &-item {
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0px;
    }
    .ant-collapse-header {
      padding-right: inherit !important;
    }
    background-color: @light-accent-lighter;
    padding-top: 40px;
    &-header {
      z-index: 1000;
    }
    .ant-input {
      background-color: white;
      border: 1px solid #e1e1e1;
    }
  }
}

.collectibles {
  &-detail {
    &__title {
      margin-bottom: 0px !important;
      padding: 1rem !important;
      font-size: 1.5rem !important;
    }
    &__stats {
      &__box {
        margin-bottom: 1rem;
        border: 1px solid @light-accent;
        border-radius: @border-radius-base;
        background: @light-accent-lighter;
        padding: @gap-md;
      }
    }
  }
}

@media screen and (max-width: 1600px) and (min-width: 1301px) {
  .collectible-form {
    &-left {
      max-width: 66.66% !important;
      flex-basis: 66.66%;
    }
    &-right {
      max-width: 33.33% !important;
      flex-basis: 33.33%;
    }
  }
}

@media screen and (max-width: 1300px) and (min-width: 1200px) {
  .collectible-form {
    &-left {
      max-width: 60% !important;
      flex-basis: 60%;
    }
    &-right {
      max-width: 40% !important;
      flex-basis: 40%;
    }
  }
}

.select-creatable-artist {
  [class$='-multiValue'] {
    border-radius: 0 !important;
    background-color: black !important;
    padding: 0.2em;
    margin-right: 1em !important;
    color: white !important;
    div {
      font-size: 13px !important;
      color: white !important;
    }
  }

  .css-tlfecz-indicatorContainer {
    color: hsl(0, 0%, 80%);
    display: none;
  }

  [class$='-control'] {
    background-color: #f8f8fa !important;
    border-color: black !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    [class$='-ValueContainer'] {
      padding: @gap-sm @gap-md !important;
    }
  }

  &-error {
    .css-1pahdxg-control,
    .css-1s2u09g-control {
      border-color: #d6222a !important;
      box-shadow: none !important;
      &:focus {
        border-color: #e3494b !important;
        box-shadow: 0 0 0 2px rgb(214 34 42 / 20%) !important;
      }
    }
  }

  .css-lgtu0rj-indicatorContainer {
    display: none !important;
  }

  .css-1hb7zxy-IndicatorsContainer {
    display: none !important;
  }

  .css-xb97g8.fans__multi-value__remove:hover {
    background-color: inherit !important;
    color: inherit !important;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(49, 44, 56, 0.2);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgb(156, 155, 155);
  }
}

.form-hidden {
  display: none;
}

.collectible-detail-artist {
  &__title {
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    color: rgba(26, 26, 26, 0.5);
  }
  &__name {
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    margin: 12px 0px;
  }
  &__bio {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
  }
  &__avatar {
    margin: 12px 0px;
  }
}
.collectible-detail-image {
  display: flex;
  @media screen and (max-width: 1600px) {
    display: block;
    .gallery-experience-page__image {
      justify-content: left;
    }
  }
  &__wrapper {
    margin-left: 4px;
    margin-top: 4px;
  }
  &__export {
    display: flex;
    align-items: center;
    margin-left: 30px;
    @media screen and (max-width: 1600px) {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    &__text {
      font-weight: 700;
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 19px;
    }
    &__button {
      margin-top: 16px;
      .ant-btn {
        padding: 12px 60px !important;
      }
    }
  }
  .gallery-experience-page__image {
    padding-bottom: 10px;
  }
  .gallery-experience-page__image__wrapper {
    position: relative;
  }
  .gallery-experience-page__image__wrapper__section {
    position: relative;
    z-index: 2;
  }
  .gallery-experience-page__image__wrapper__background {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.print-qr-collectible {
  &-download {
    &:hover {
      color: #1a1a1a;
    }
  }
  font-family: 'Satoshi', sans-serif !important;
  color: #2b2b2b !important;
  .gallery-experience-page__image__wrapper {
    padding: 60px;
    position: relative;
  }
  .gallery-experience-page__image__wrapper__section {
    position: relative;
    z-index: 2;
  }
  .gallery-experience-page__image__wrapper__section__description {
    &__bottom {
      margin-top: 16px;
      svg {
        height: 62px;
        width: auto;
      }
    }
    margin-bottom: 30px;
  }
  .gallery-experience-page__image__wrapper__section__description__heading {
    font-size: 55px;
  }
  .gallery-experience-page__image__wrapper__section__description__description {
    font-size: 35px;
    margin-top: 20px;
  }
  .gallery-experience-page__image__wrapper__section__description__logo {
    margin-bottom: 16px;
    svg {
      height: 62px;
      width: auto;
    }
  }
  .gallery-experience-page__image__wrapper__section__image {
    padding: 30px;
    border-radius: 15px;
    &__text {
      font-size: 35px;
      margin-bottom: 30px;
    }
  }
  .gallery-experience-page__image__wrapper__section__bottom {
    margin-top: 30px;
    svg {
      height: 62px;
      width: auto;
    }
  }
  .gallery-experience-page__image__wrapper__background {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.print-frame {
  &__text-cm {
    margin-top: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: rgba(26, 26, 26, 0.5);
  }
  &__form {
    .ant-form-item {
      margin-bottom: 30px !important;
    }
  }
  &__top {
    .ant-form-item {
      margin-bottom: 0px !important;
    }
  }
  &__flex {
    display: flex;
    &__left {
      width: 40%;
    }
  }
  &__uri {
    object-fit: scale-down;
    width: 90%;
    height: 90%;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.08));
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  &__separator {
    height: 1px;
    background: #e1e1e1;
    width: 100%;
    margin-bottom: 30px;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 490px;
    background-color: #f8f8fa;
  }
  &__show {
    position: absolute;
    z-index: -1;
    opacity: 0;
    &__wrapper {
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__barcode {
    &__label {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
    }
    @apply w-full flex justify-center;
    margin-top: 50px;
    &__wrapper {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      width: 100%;
      max-width: 547px;
    }
    &__download {
      @apply absolute;
      z-index: -1;
      opacity: 0;
      &__content {
        font-family: 'Satoshi', sans-serif !important;
        background-color: white;
        width: 1200px;
        display: flex;
        align-items: center;
        padding: 50px;
        &__wrapper {
          align-items: center;
          text-align: center;
        }
        img {
          height: 400px;
          width: 400px;
        }
        &__title {
          font-weight: 700;
          font-size: 64px;
          line-height: 150%;
          margin-bottom: 20px;
        }
        &__description {
          font-weight: 400;
          font-size: 32px;
          line-height: 170%;
          &__medium {
            font-weight: 700;
          }
        }
        &__year {
          font-weight: 400;
          font-size: 32px;
          line-height: 200%;
        }
        &__right {
          width: 1100px;
          display: flex;
          justify-content: center;
        }
        &__left {
          width: 100%;
        }
      }
    }
  }
}
