.patrons-modal {
  font-family: 'Satoshi', sans-serif;
  .ant-modal-close-icon {
    svg {
      height: 18px;
      width: 18px;
    }
  }
  .ant-form-item-label > label {
    font-weight: 500;
    font-size: 18px;
    line-height: 170%;
    letter-spacing: -0.02em;
  }
  .ant-input-password {
    padding: 10px 20px;
    font-size: 18px;
    line-height: 170%;
    background: #f5f5f5;
  }
  .ant-input {
    padding: 10px 20px;
    font-size: 18px;
    line-height: 170%;
    background: #f5f5f5;
  }
  &__title {
    font-weight: 700;
    font-size: 36px;
    line-height: 150%;
    text-align: center;
  }
  &__description {
    font-size: 18px;
    line-height: 170%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #5c5c5c;
    width: 100%;
    max-width: 480px;
    margin-top: 6px;
  }
  &__form {
    margin-top: 20px;
    &__flex {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 600px) {
        display: block;
      }
      gap: 30px;
      &__item {
        width: 100%;
      }
    }
  }
  &__button {
    margin-top: 30px;
    .ant-btn {
      width: 100% !important;
      height: 55px !important;
    }
  }
  &__success {
    &__title {
      font-weight: 700;
      font-size: 26px;
      line-height: 150%;
      letter-spacing: -0.02em;
      text-align: center;
    }
    &__description {
      font-size: 18px;
      line-height: 170%;
      text-align: center;
      letter-spacing: -0.02em;
      color: #5c5c5c;
      width: 445px;
      margin-top: 26px;
      margin-bottom: 50px;
    }
  }
}

.patrons-modal__register {
  .ant-form-item-control {
    height: fit-content;
  }
  .ant-form-item-required::before {
    display: none !important;
  }
  &__label {
    font-weight: 500;
    font-size: 18px;
    line-height: 170%;
    letter-spacing: -0.02em;
    margin-bottom: 8px;
  }
  &__interested {
    width: 100%;
    display: flex;
    margin-bottom: 24px;
    border-radius: 4px;
    align-items: center;
    justify-content: space-between;
    .interested__active {
      border: 1px solid #2b2b2b !important;
    }
    &__option {
      text-transform: capitalize;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 9.5px 0px;
      border: 1px solid #e0e0e0;
      color: #979797;
      font-size: 14px;
      color: #1a1a1a;
      line-height: 170%;
      letter-spacing: -0.02em;
    }
    &__right {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &__left {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
}
