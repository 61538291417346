.banner {
  background: url('../../assets/img/banner.png');
  background-size: cover;
  z-index: 100;
  position: relative;

  &-wrapper {
    padding: 4.45rem 0 7.375rem;
  }

  &__title {
    margin-bottom: 1rem !important;
    font-family: @montserrat;
    font-weight: 700 !important;
    color: @white !important;
    line-height: 4rem;
  }
  &__breadcrumbs {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 500 !important;
    font-family: @montserrat;
    color: @white !important;
    a {
      font-weight: 300 !important;
      color: @white !important;
    }
    svg {
      margin: 0 0.525rem;
      font-size: 10px;
    }
  }
}

@media screen and (max-width: @screen-md) {
  .banner {
    &-wrapper {
      padding: 3rem 0 5rem;
    }
    &__title {
      margin-bottom: 5px !important;
      font-size: 2rem !important;
    }
    &__breadcrumbs {
      font-size: 13px;
    }
  }
}
