.collectible-card-v2 {
  .separator-dark {
    border-top: 1px solid #000000;
    border-bottom: 1px solid #3f3f3f;
  }
  &__image {
    &-overlay {
      font-family: Avenir Next Condensed, sans-serif;
      background-color: rgba(0, 0, 0, 0.7);
      position: absolute;
      opacity: 0;
      top: 0;
      height: 100%;
      width: 100%;
      display: flex;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 300ms;
      &:hover {
        opacity: 1;
      }
      &-body {
        margin: auto;
        width: 100%;
        &-title {
          color: white;
          text-transform: uppercase;
          text-align: center;
          font-size: 1.125rem;
        }
        &-button {
          cursor: pointer;
          margin-top: 20px;
          display: flex;
          color: black;
          background-color: white;
          justify-content: space-between;
          align-items: center;
          text-align: center;
          font-weight: 600;
          text-transform: uppercase;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          margin-right: auto;
          margin-left: auto;
          border-radius: 30px;
          border: 1px solid white;
          transition-property: all;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-duration: 150ms;
          transition-duration: 200ms;
          width: 90%;
          max-width: 191px;
          padding: 6px 8px;
          &:hover {
            background-color: rgba(0, 0, 0, 0.7);
            color: white;
          }
        }
      }
    }
    position: relative;
    border-radius: @border-radius-base;
    z-index: 2;
    background: @light-accent-lighter;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
    aspect-ratio: 1;
    .ant-image {
      display: block;
    }
    img {
      border-radius: @border-radius-base;
      aspect-ratio: 1;
      object-fit: cover;
      width: 100%;
    }
    &__player {
      position: absolute;
      top: 0;
      z-index: 1; /* matters! */
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #0000008f 100%);
      border-radius: 5px;
      width: 100%;
      height: 100%;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.842) 0%, rgba(0, 212, 255, 0) 100%);
        z-index: -1;
        opacity: 0;
        transition: all 0.4s;
        border-radius: 5px;
      }
      &:hover::before {
        opacity: 1;
      }
    }
  }
  &__placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    &__content {
      text-align: center;
      color: @dark-50;
      .anticon {
        margin-bottom: @gap-md;
        font-size: 4rem;
        color: @dark-50;
      }
    }
  }
  &__detail {
    position: relative;
    border-radius: @border-radius-base;
    padding: 16px 19px;
    z-index: 0;
    background: @white;
    color: #2b2b2b;
    font-family: 'Satoshi', sans-serif;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
    &__container {
      display: flex;
      align-items: center;
      margin-bottom: 9px;
    }
    &__title {
      margin-bottom: 3px !important;
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
      color: #2b2b2b !important;
    }
    &__text {
      font-style: normal;
      font-weight: 500;
      font-size: 12.8px;
      line-height: 170%;
      color: #5c5c5c;
      &__bold {
        font-weight: 700;
        text-decoration: underline;
      }
    }
    &__url {
      font-size: 0.9rem;
      color: @dark-50;
    }
  }
}
