.offers-toolbar {
  margin-top: @gap-lg;
  margin-bottom: @gap-lg;

  .ant-select {
    display: block;
  }
}

.offers {
  .avatar-name {
    padding-left: @gap-sm;
  }
}

.offers-modal {
  &-container {
    display: flex;
    > div:first-child {
      margin-right: 1.5rem;
    }

    > div:nth-child(2) {
      width: 100%;
    }

    .user-row {
      display: flex;
      > div:first-child {
        margin-right: 2rem;
      }
      .user-row-item {
        display: flex;
        .user-information {
          margin-left: 15px;
        }
        .title {
          color: rgba(26, 26, 26, 0.5);
        }
      }
    }

    .offer-detail {
      display: flex;
      justify-content: space-between;
    }
  }
  .ant-modal-close {
    &-x {
      font-size: 1rem;
      .anticon {
        color: @light-text-50;
        &:hover {
          color: @light-text;
        }
      }
    }
  }

  .ant-modal-title {
    font-size: 1.25rem;
  }
}
