.balance-admin {
  &__amount {
    margin-bottom: @gap-lg;
    background-color: @light-accent-lighter;
    padding: 36px;
    &-desc {
      padding-bottom: 36px;
    }
    &-current {
      border: 1px dashed rgba(26, 26, 26, 0.5);
      padding: 36px;
      text-align: center;
    }
    &-text {
      text-transform: uppercase;
      display: block;
      font-size: 13px;
      line-height: 16px;
      color: rgba(26, 26, 26, 0.5);
    }
    &-value {
      font-size: 32px;
      font-weight: bold;
      display: block;
    }
  }
}

.transactions-modal {
  .ant-modal-close {
    &-x {
      font-size: 1rem;
      .anticon {
        color: @light-text-50;
        &:hover {
          color: @light-text;
        }
      }
    }
  }
  .transactions-modal-container {
    padding: 32px;
    &-header {
      text-align: center;
      &-title {
        font-weight: 700;
        font-size: 20px;
        margin-top: 25px;
        margin-bottom: 20px;
      }
      &-text {
        color: rgba(26, 26, 26, 0.5);
        text-align: center;
      }
    }
    &-detail {
      &-table {
        width: 100%;
        &-row {
          &-left {
            width: 30%;
            font-weight: 700;
            font-size: 15px;
          }
          border-bottom: 1px solid #f1f1f1;
          line-height: 50px;
          &-description {
            line-height: 16px;
          }
        }
      }
    }
    &-footer {
      width: 100%;
      margin-top: 20px;
      text-align: center;
      .ant-btn {
        border-radius: 0px !important;
      }
      &-viewInvoice {
        width: 100%;
        height: 50px;
        font-size: 16px;
      }
    }
  }
  .ant-modal-body {
    padding-top: 10px;
  }
}
