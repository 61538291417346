.admin-organization {
  &-modal {
    .ant-modal-body {
      padding: 40px 32px 32px;
    }
    &__icon {
      margin-bottom: 2rem;
      font-size: 70px;
    }
    &__desc {
      color: #1a1a1a80;
      &-revision {
        margin: -12px -8px -8px;
        text-align: left;
      }
    }
    &__button {
      font-size: 13px;
      padding: 13px 29.5px !important;
      line-height: 19px;
      height: auto;
      &-default {
        background: #f1f1f1;
        border-color: #f1f1f1;
        margin-left: 20px;
      }
      &-error {
        background: #d6222a;
        border-color: #d6222a;
        color: white;
      }
      &-success {
        background: #27ae60;
        border-color: #27ae60;
        color: white;
        &:hover {
          border-color: #27ae60;
          color: #27ae60;
        }
      }
    }
  }
  &__heading {
    padding: 20px;
    background: @light-accent-lighter;
    .content-container {
      margin-block: 26px;
    }
    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
    }
    .subtitle {
      font-weight: 400;
      font-size: 13px;
      line-height: 13px;
      color: rgba(26, 26, 26, 0.5);
      display: block;
      margin-bottom: 8px;
    }
    .content-text {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: @dark;
    }
    .edit-organization-btn {
      padding-block: 12px;
      padding-inline: 32px;
      height: auto !important;
    }
  }
  &__search {
    margin-block: 20px;
  }
}
