.cropper {
  &__inner {
    position: relative;
    height: auto;
    &__image {
      position: relative;
      height: 240px;
      background: @midnight;
    }
    &__control {
      margin-top: @gap-md;
      &__button {
        text-align: right;
      }
    }
  }
}
