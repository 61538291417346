.delete-collectible-input {
  .ant-select-selection-search-input {
    margin-top: 0.4em !important;
  }
}
.approve-collectible-input {
  .ant-select-selection-search-input {
    margin-top: 0.4em !important;
  }
}
