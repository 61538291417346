.pagination {
  margin-top: 1rem;
  &__button {
    margin-left: auto;
    @media only screen and (max-width: @screen-lg) {
      margin: auto;
    }
    &__next {
      margin-left: 0.5rem;
      padding-top: 2rem;
    }
  }
}
