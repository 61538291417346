@import '@components/avatar/style.less';
@import '@components/banner/style.less';
@import '@components/collectible-card/style.less';
@import '@components/container/style.less';
@import '@components/countdown/style.less';
@import '@components/cropper/style.less';
@import '@components/edition-badge/style.less';
@import '@components/footer/style.less';
@import '@components/navigation/style.less';
@import '@components/notification-menu/style.less';
@import '@components/pagination/style.less';
@import '@components/player/style.less';
@import '@components/price/style.less';
@import '@components/rich-text-editor/style.less';
@import '@components/serial-item/style.less';
@import '@components/single-page-content/style.less';
@import '@components/suspense-loading/style.less';
@import '@components/withdrawal-modal/style.less';
@import '@components/image-collage/style.less';
@import '@components/vip-card/style.less';

@import '@pages/dashboard/style.less';
@import '@pages/bids/style.less';
@import '@pages/creators/style.less';
@import '@pages/teams/style.less';
@import '@pages/offers/style.less';
@import '@pages/user-settings/style.less';
@import '@pages/collectibles/style.less';
@import '@pages/collectibles/style-new.less';
@import '@pages/single-page/style.less';
@import '@pages/settings/style.less';
@import '@pages/dummy/style.less';
@import '@pages/balance/style.less';
@import '@pages/admin/notifications/style.less';
@import '@pages/admin/transactions/style.less';
@import '@pages/admin/withdrawal/style.less';
@import '@pages/admin/collectibles/style.less';
@import '@pages/admin/order-history/style.less';
@import '@pages/admin/user-activities/style.less';
@import '@pages/admin/organization/style.less';
@import '@pages/admin/users/style.less';
@import '@pages/beta-features/style.less';
@import '~@vincere/react-vlag/dist/style.css';

@font-face {
  font-family: 'Avenir';
  font-weight: 400;
  src: url('../fonts/AvenirRegular.otf') format('otf');
}
@font-face {
  font-family: 'Avenir Next Condensed';
  font-weight: 400;
  src: url('../fonts/AvenirNextCondensed.woff2') format('woff2'),
    url('../fonts/AvenirNextCondensed.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next Condensed';
  font-weight: 600;
  src: url('../fonts/AvenirNextCondensedDemiBold.woff2') format('woff2'),
    url('../fonts/AvenirNextCondensedDemiBold.woff') format('woff');
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Form */
.ant-form {
  &-item {
    &-has-error {
      .ant-picker {
        border-color: #ff4d4f !important;
      }
    }
  }

  &.fans-form {
    .ant-form-item {
      .ant-form-item-control-input {
        .ant-input-prefix {
          margin-right: 10px;
        }
      }

      &.height-auto {
        .ant-form-item-control-input {
          min-height: unset;
        }
      }

      .ant-btn[type='submit'] {
        padding: 0 4rem;
      }
    }
  }
}

/* Table */
.fans-table {
  .ant-table {
    background: none;

    &-thead {
      .ant-table-cell {
        border-bottom: 2px solid @light-accent;
        padding: @gap-sm;
        font-size: 13px;
        font-weight: 400;
        color: @light-text-50;
        background: transparent;
      }
    }

    &-tbody {
      & > tr.ant-table-placeholder {
        &:hover > td {
          background: none;
        }
      }
    }

    &-row {
      .ant-table-cell {
        padding: @gap-sm !important;
        border-bottom-color: @light-accent;
        font-size: 13px;

        a {
          .ant-typography {
            color: @light-text;
          }

          &:hover {
            .ant-typography {
              color: @primary-color;
            }
          }
        }

        .status {
          color: @light-text-50;
          font-weight: 400;
        }

        .title {
          margin-bottom: @gap-xs;
        }

        .meta {
          font-weight: 400;
          color: @light-text-50;
          font-size: 0.625rem;

          @media screen and (max-width: @screen-sm) {
            font-size: 13px;
          }

          & > span {
            margin-right: @gap-sm;
          }

          a {
            color: @light-text-50;

            &:hover {
              color: @primary-color;
            }
          }
        }

        .mobile-row {
          &__info {
            &__name {
              margin-bottom: @gap-xs;
            }
          }
        }

        .datalist {
          .ant-collapse-header {
            padding: @gap-sm 0;
          }

          .ant-collapse-content-box {
            padding: 0;
          }

          .ant-list {
            &-item {
              padding: 0;

              &-meta {
                padding-bottom: @gap-sm;
                border-bottom: 1px solid @light-accent;

                &-title {
                  font-size: 13px;
                  margin-bottom: @gap-sm;
                  font-weight: 400;
                }

                .avatar-name {
                  .ant-avatar {
                    margin-right: @gap-sm;
                  }
                }
              }
            }
          }
        }
      }

      &:hover {
        .ant-table-cell {
          background: @light-accent-lighter;
        }
      }
    }

    &.ant-table-bordered {
      .ant-table-container {
        border-left: none;
      }

      table {
        border-top: none !important;
      }

      .ant-table-cell {
        border-right: none !important;
      }
    }
  }

  .ant-pagination {
    &-item {
      background: @light-accent-lighter !important;
      border-color: @light-accent-lighter;

      &-active {
        border-color: @dark;
        background: @dark !important;

        a {
          color: @white;

          &:hover {
            color: @white;
          }
        }
      }

      &-link {
        background: @light-accent-lighter !important;
        border: none !important;
      }
    }

    &-disabled {
      .ant-pagination-item-link {
        color: @light-text-50 !important;
      }
    }
  }

  .ant-tag {
    padding: 6px @gap-sm;
    border-color: @light-accent;
    background: @light-accent-lighter;
    font-size: 10px;
    line-height: 10px;
    color: @dark-secondary;
    min-width: 65px;
    text-align: center;

    &.warning {
      background: @warning-color;
      color: @white;
    }

    &.success {
      background: @success-color;
      color: @white;
    }

    &.error {
      background: @error-color;
      color: @white;
    }
  }
}

/* Alert */
.ant-alert {
  &-message {
    font-size: 0.875rem;
    color: @dark !important;
  }
}

/* Button */
.ant-btn {
  padding: 0 @gap-md;
}

/* Notification */
.ant-notification {
  &-notice {
    background: @white;

    &-message {
      font-size: 1.25rem !important;
      font-weight: 500;
    }

    &-description {
      font-size: 0.85rem !important;
    }

    &-close {
      font-size: @font-body-sm;

      .anticon {
        svg {
          color: @dark-50;
        }
      }
    }

    &-with-icon {
      .anticon {
        top: 50%;
        margin-top: -12px;
        width: 24px;
        height: 24px;
      }
    }

    &.ant-notification-notice-info,
    &.ant-notification-notice-success,
    &.ant-notification-notice-warning,
    &.ant-notification-notice-error {
      .ant-notification-notice-icon,
      .ant-notification-notice-message,
      .ant-notification-notice-description {
        color: @white;
      }

      .ant-notification-notice-close {
        .anticon {
          svg {
            color: @white;
          }
        }
      }
    }

    &.ant-notification-notice-info {
      background: @info-color;
    }

    &.ant-notification-notice-success {
      background: @success-color;
    }

    &.ant-notification-notice-error {
      background: @error-color;
    }

    &.ant-notification-notice-warning {
      background: @warning-color;
    }
  }
}

/* Tooltip */
.ant-tooltip {
  border-radius: @border-radius-base;
  font-size: 0.85rem;

  &-inner {
    background: @dark;
  }

  a {
    padding-right: @gap-sm;
    color: @white;
  }
}

/* Select dropdown */
.ant-select {
  &-dropdown {
    padding: 0 !important;
    background: @light-accent !important;

    .rc-virtual-list {
      &-holder {
        background: @white;

        .ant-select-item {
          padding: @gap-sm @gap-md !important;

          &.ant-select-item-group {
            border-bottom: 1px solid @light-accent-lighter;
            padding: @gap-sm @gap-md !important;
            color: @dark-50 !important;
          }

          .ant-select-item-option {
            padding: @gap-sm @gap-md !important;
          }
        }

        .ant-select-item-option-grouped {
          padding-left: @gap-md !important;
        }
      }
    }
  }

  &-selector {
    height: 34px !important;

    .ant-select-selection-search-input,
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      line-height: 34px !important;
    }
  }

  &-item {
    &-group {
      color: @white !important;
    }

    &-option {
      color: @dark !important;

      &-active,
      &-selected {
        &:not(.ant-select-item-option-disabled) {
          background: @light-accent-lighter !important;
          font-weight: 400 !important;
          color: @dark !important;
        }

        &:hover {
          color: @white !important;
          background: @dark !important;
        }
      }
    }
  }
}

/* Empty */
.ant-empty {
  &-description {
    color: @off-text-color;
  }
}

/* CUSTOM
================================== */
.toolbar {
  margin-bottom: @gap-lg;

  @media only screen and (max-width: @screen-xl) {
    margin-bottom: @gap-md;
  }

  &__left {
    @media only screen and (max-width: @screen-xl) {
      margin-bottom: @gap-md;
    }

    .ant-btn {
      border-radius: @border-radius-base;
      padding: 0 @gap-md;
      font-weight: 400;
      height: 40px !important;

      @media only screen and (max-width: @screen-xl) {
        padding: 0 @gap-md;
      }
    }
  }

  &__right {
    display: flex !important;
    justify-content: flex-end;
    align-items: center;

    & > div {
      margin-left: @gap-md;
    }

    & > div:first-child {
      @media only screen and (max-width: @screen-xl) {
        margin-left: 0;
      }
    }
  }

  &-search {
    flex: 1;

    @media screen and (min-width: @screen-lg) {
      max-width: 255px;
    }

    .ant-select {
      width: 100%;
    }

    .ant-select-selector {
      display: block !important;
      height: 40px !important;
      background: @light-accent-lighter !important;
    }

    .ant-select-selection-search-input,
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      line-height: 40px !important;
    }

    .ant-input-search {
      .ant-input {
        padding: 1rem 1.25rem;
        height: 40px;
      }

      .ant-input-group {
        height: 100%;
      }

      .ant-input-group-addon {
        background: none;

        .ant-btn {
          height: 100%;
        }

        .ant-input-search-button {
          background: @light-accent-lighter;
          border: none;
          padding: 0;

          &.ant-btn-loading {
            display: flex;
            align-items: center;
            justify-content: center;

            &:before {
              background: @light-accent;
            }

            &-icon {
              display: block;
            }
          }
        }

        .anticon {
          font-size: 1rem;
          color: @light-text-50;
        }
      }
    }
  }

  &-filter {
    margin-right: @gap-md;

    .ant-select {
      &-selector {
        padding: 1rem 1.25rem;
        background: @light-accent !important;
        border-color: @light-accent !important;
      }

      &-focused {
        .ant-select-selector {
          border-color: @primary-color !important;
        }
      }

      &-arrow {
        color: @white;
      }
    }

    &__dropdown {
      background: @light-accent !important;

      .rc-virtual-list-holder-inner {
        .ant-select-item-option {
          &-active {
            background: @primary-color;
          }
        }
      }
    }
  }

  &-switch {
    display: flex;

    .anticon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      font-size: 1.25rem;
      cursor: pointer;
      background: @light-accent-lighter;
      transition: all 0.2s ease;

      &:hover,
      &.active {
        background: @dark;
        color: @white;
      }

      &:first-child {
        border-radius: @border-radius-base 0 0 @border-radius-base;
      }

      &:last-child {
        border-radius: 0 @border-radius-base @border-radius-base 0;
      }
    }
  }
}

.admin-form {
  &__group {
    margin: 0 0 @gap-lg;

    &__heading {
      margin-bottom: @gap-sm;
      padding-bottom: @gap-sm;
      border-bottom: 1px solid @light-accent;
      font-size: @font-body-lg;
      font-weight: 500;
    }

    &__description {
      margin-bottom: @gap-lg;
      font-size: @font-body-md;
      color: @dark-50;
    }
  }

  &-hr {
    margin-bottom: 1rem;
    border-top: 2px solid @light-accent !important;
  }

  &-release {
    &-title {
      font-size: 1rem !important;
    }
  }

  &-hidden {
    display: none !important;
  }

  &.ant-form-vertical {
    .ant-form-item {
      &.required {
        .ant-form-item-label > label::before {
          display: inline-block;
          margin-left: 4px;
          color: #ff4d4f;
          font-size: 13px;
          font-family: SimSun, sans-serif;
          line-height: 1;
          content: '*';
        }
      }

      &-label {
        padding-bottom: @gap-xs;

        & > label {
          height: auto;
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
          font-size: 13px;

          &.ant-form-item-required {
            &:not(.ant-form-item-required-mark-optional) {
              &::before {
                margin-left: 4px;
              }
            }
          }
        }
      }
    }
  }

  .ant-form-item {
    font-size: 12px;

    &-explain,
    &-extra {
      margin-top: @gap-xs;
      font-size: @font-body-sm;
    }

    .ant-checkbox-wrapper {
      a {
        text-decoration: underline;
      }
    }
  }

  .ant-input {
    padding: @gap-sm @gap-md;

    &-group {
      &-addon {
        background: @light-accent;
      }

      .ant-btn {
        padding: 0.8rem 1.25rem !important;
      }
    }
  }

  .ant-input-number {
    &-input {
      height: 34px !important;
      padding: @gap-sm @gap-md;
      font-size: 12px;
      line-height: 13px;
    }

    &-group-wrapper {
      display: block;
    }

    &.block {
      width: 100% !important;
    }
  }

  .ant-btn {
    padding: 1rem 3rem;
    height: auto;
  }

  /* Select */
  .ant-select {
    font-size: 12px;

    &-arrow {
      right: 1.5rem;
      font-size: 12px;
      color: @light-text;
    }

    &-multiple {
      .ant-select-selector {
        box-sizing: content-box;
        padding: @gap-sm @gap-md;
      }

      .ant-select-selection {
        &-search {
          margin: 0 !important;

          &-input {
            height: auto;
          }
        }

        &-item {
          display: flex;
          align-items: center;
          margin: 0 @gap-md 0 0;
          height: 2rem;
          background: @dark;
          color: @white;

          &-remove {
            color: @white;
          }
        }

        &-placeholder {
          left: 1.25rem;
        }

        &-item {
          &-content {
            color: @white;
            font-size: 13px;
          }
        }
      }
    }

    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        border: 1px solid @light-accent-lighter;
        height: 34px !important;
        color: @dark;
        background: @light-accent-lighter;
      }
    }
  }

  .ant-upload {
    &.ant-upload-drag {
      background: @light-accent-lighter !important;
      padding: @gap-md;

      .ant-upload-hint {
        font-size: 12px !important;
        line-height: 14.52px;
      }
    }

    &-text {
      font-size: 11px !important;
      font-weight: 700;
      line-height: 16px;
    }
  }
}

/* Image preview field */
.image-preview-field {
  position: relative;
  border-radius: @border-radius-base;
  background: @light-accent-lighter;

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  &__overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: all 0.3s ease;
    cursor: pointer;

    .anticon {
      font-size: 1.5rem;
      color: @white;
    }
  }

  &:hover {
    .image-preview-field__overlay {
      opacity: 1;
    }
  }

  &.rounded {
    border-radius: 100%;
  }

  &.showOverlay {
    .image-preview-field__overlay {
      opacity: 0.4;
    }
  }

  &__modal {
    .ant-modal-header {
      border-color: @light-accent;

      .ant-modal-title {
        font-size: 1.25rem;
      }
    }

    .ant-modal-content {
      .ant-modal-close {
        &-x {
          font-size: 1rem;

          .anticon {
            color: @light-text-50;

            &:hover {
              color: @light-text;
            }
          }
        }
      }
    }
  }
}

/* Tabs */

.ant-tabs {
  &-top > &-nav::before {
    border-bottom: 1px solid @light-accent !important;
  }

  &-tab {
    font-size: 0.875rem !important;
    line-height: 1rem !important;
  }
}

/* Datepicker */
.ant-picker {
  background: @light-accent-lighter !important;
  border-color: @light-accent-lighter !important;
  padding: @gap-sm @gap-md !important;
  width: 100%;

  &-header,
  &-footer {
    border-top: 1px solid @light-accent !important;
    border-bottom: 1px solid @light-accent !important;
  }

  &-time {
    &-panel,
    &-panel-column:not(:first-child) {
      border-left: 1px solid @light-accent !important;
    }

    &-panel-cell:hover,
    &-panel-cell-selected {
      color: @white !important;

      .ant-picker-time-panel-cell-inner {
        color: @white !important;
      }
    }
  }

  &-suffix {
    color: @dark !important;
  }

  &-panel {
    border: none !important;

    &-container {
      border-radius: @border-radius-base;
      background: @white !important;
    }
  }
}

.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner,
.ant-picker-time-panel-cell-inner:hover {
  background: @primary-color !important;
}

/* Avatar */
.ant-avatar {
  background: @light-accent;
}

/* Modal */
.ant-modal-content {
  border-radius: 0px !important;
}

@white: #ffffff;@green: #27AE60;@dark: #1A1A1A;@darkSecondary: #2B2B2B;@dark-50: rgba(26,26,26,0.5);@dark-accent: #383E54;@dark-secondary: #2B2B2B;@dark-border: #4A4A4A;@fans-red: #D6222A;@light-accent: #E9E9EB;@light-text: #24293D;@light-text-50: rgba(36,41,61,0.5);@light-accent-lighter: #F8F8FA;@info-color: #0077F8;@warning-color: #e2b93b;@success-color: #27AE60;@error-color: #D6222A;@midnight: #1E2235;@midnight-lighter: #24293d;@off-text-color: #bdbdbd;@primary-color: @dark;@montserrat: Montserrat;@inter: Inter;@font-body-xs: 0.625rem;@font-body-sm: 0.8125rem;@font-body-md: 1rem;@font-body-lg: 1.125rem;@font-heading-1: 1.5rem;@font-heading-2: 1.25rem;@gap-xs: 0.25rem;@gap-sm: 0.625rem;@gap-md: 1rem;@gap-lg: 1.875rem;@gap-xl: 2.5rem;@font-family: Inter, sans-serif;@font-size-base: 13px;@text-color: @dark;@heading-color: @dark;@link-color: @primary-color;@border-radius-base: 0;@layout-body-background: @white;@layout-sider-background: @dark;@card-head-font-size: @font-body-md;@height-base: 34px;@input-bg: @light-accent-lighter;@input-icon-color: @light-text;@input-border-color: @light-accent-lighter;@menu-bg: @layout-sider-background;@menu-item-color: @white;@menu-item-active-bg: transparent;@menu-item-hover-bg: transparent;@menu-selected-item-text-color: @fans-red;@menu-selected-item-icon-color: @fans-red;@alert-text-color: @dark;@btn-default-ghost-color: @dark;@btn-default-ghost-border: @dark;@screen-xs: 480px;@screen-sm: 576px;@screen-md: 768px;@screen-lg: 992px;@screen-xl: 1200px;@screen-xxl: 1600px;@screen-xs-max: @screen-sm - 1px;@screen-sm-max: @screen-md - 1px;@screen-md-max: @screen-lg - 1px;@screen-lg-max: @screen-xl - 1px;@screen-xl-max: @screen-xxl - 1px;