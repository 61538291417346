.bids-toolbar {
  margin-top: @gap-lg;
  margin-bottom: @gap-lg;

  .ant-select {
    display: block !important;
  }
}

.bids {
  .avatar-name {
    padding-left: @gap-sm;
  }
}

.bids-modal {
  .ant-modal-close {
    &-x {
      font-size: 1rem;
      .anticon {
        color: @light-text-50;
        &:hover {
          color: @light-text;
        }
      }
    }
  }

  .ant-modal-title {
    font-size: 1.25rem;
  }

  .offer-table {
    > .offer-row {
      display: flex;
      font-size: 14px;
      margin-bottom: @gap-sm;

      > div {
        width: 50%;

        span {
          font-weight: 700;
        }
      }

      .collectible-detail {
        flex-direction: column;
      }

      .avatar-wrapper {
        display: flex;
        align-items: center;

        p {
          margin: 0 0 0 @gap-xs;
        }
      }
    }
  }
}
