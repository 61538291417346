.player {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  &__controls {
    display: flex;
    align-items: center;
    padding: 5px;
    .ant-btn {
      padding: 0 !important;
      height: max-content;
    }
    .ant-slider {
      flex: 1;
    }
    &__button {
      img {
        width: 1em;
        height: 1em;
      }
    }
    &__duration {
      color: white;
      font-size: 0.8rem;
      margin: 0 5px;
    }
    &__seek {
      .ant-slider-rail {
        background: rgba(255, 255, 255, 0.5);
      }
    }
  }
}
