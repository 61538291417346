.modal-email {
  .ant-modal-header {
    padding: 2rem;
    .ant-modal-title {
      font-size: xx-large;
    }
  }
  .ant-modal-footer {
    color: white;
    .ant-btn {
      background: @dark;
      color: @white;
      border-color: white;
    }
  }
  .ant-modal-close {
    display: none;
  }
}
.input-w-button {
  margin-left: 1rem;
  margin-top: 1.6rem;
}

.user-settings {
  .admin-form .ant-btn {
    padding: 0;
    padding-right: 2rem;
    padding-left: 2rem;
    height: 100%;
  }
}

.add-user-images {
  @media only screen and (max-width: @screen-lg) {
    text-align: center;
  }
}
