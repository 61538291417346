.auth {
  min-height: 100vh;
  font-family: 'Satoshi', sans-serif;
  background-color: #fbfbfb;
  .ant-form-item-control-input {
    border: 1px solid #e1e1e1;
    border-radius: 4px;
  }
  &-forgot {
    color: #2b2b2b;
    min-height: calc(100vh - 160px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    &__wrapper {
      width: 100%;
      max-width: 560px;
    }
    &__title {
      font-weight: 700;
      font-size: 36px;
      line-height: 150%;
      margin-bottom: 30px;
      @media screen and (max-width: 500px) {
        font-size: 28px;
        line-height: 120%;
      }
    }
    &__button {
      .ant-btn {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
        line-height: 170%;
        height: 55px;
      }
    }
  }
  &-reset {
    color: #2b2b2b;
    min-height: calc(100vh - 160px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    &__wrapper {
      width: 100%;
      max-width: 560px;
    }
    &__description {
      @media screen and (max-width: 500px) {
        font-size: 14px;
        line-height: 150%;
        margin-top: 20px;
      }
      font-weight: 400;
      font-size: 18px;
      line-height: 170%;
      letter-spacing: -0.02em;
      margin-bottom: 20px;
    }
    &__title {
      font-weight: 700;
      font-size: 36px;
      line-height: 150%;
      margin-bottom: 6px;
      @media screen and (max-width: 500px) {
        font-size: 28px;
        line-height: 120%;
      }
    }
    &__button {
      .ant-btn {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
        line-height: 170%;
        height: 55px;
      }
    }
  }
  &-invitation {
    color: #2b2b2b;
    min-height: calc(100vh - 160px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    &__wrapper {
      width: 100%;
      max-width: 560px;
    }
    &__description {
      @media screen and (max-width: 500px) {
        font-size: 14px;
        line-height: 150%;
        margin-top: 20px;
      }
      font-weight: 400;
      font-size: 18px;
      line-height: 170%;
      letter-spacing: -0.02em;
      margin-bottom: 20px;
    }
    &__title {
      font-weight: 700;
      font-size: 36px;
      line-height: 150%;
      margin-bottom: 6px;
      @media screen and (max-width: 500px) {
        font-size: 28px;
        line-height: 120%;
      }
    }
    &__button {
      .ant-btn {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
        line-height: 170%;
        height: 55px;
        width: 100%;
      }
    }
  }
  &-join {
    color: #2b2b2b;
    min-height: calc(100vh - 160px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    &__wrapper {
      width: 100%;
      max-width: 560px;
    }
    &__description {
      @media screen and (max-width: 500px) {
        font-size: 14px;
        line-height: 150%;
        margin-top: 20px;
      }
      font-weight: 400;
      font-size: 18px;
      line-height: 170%;
      letter-spacing: -0.02em;
      margin-bottom: 20px;
    }
    &__title {
      font-weight: 700;
      font-size: 36px;
      line-height: 150%;
      margin-bottom: 6px;
      @media screen and (max-width: 500px) {
        font-size: 28px;
        line-height: 120%;
      }
    }
    &__button {
      .ant-btn {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
        line-height: 170%;
        height: 55px;
        width: 100%;
      }
    }
  }
  &-landing {
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-btn {
      border-radius: 4px;
    }
    &__wrapper {
      width: 100%;
      max-width: 1300px;
      margin: 0px 20px;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 500px) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    &__image {
      margin-top: 124px;
      img {
        width: 100%;
      }
    }
    &__top {
      display: flex;
      margin-top: 100px;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 20px;
      @media screen and (max-width: 500px) {
        display: block;
        margin-top: 80px;
      }
      &__left {
        @media screen and (max-width: 820px) {
          font-size: 28px;
          line-height: 120%;
        }
        width: 100%;
        max-width: 520px;
        font-weight: 700;
        font-size: 36px;
        line-height: 150%;
        letter-spacing: -0.02em;
      }
      &__right {
        @media screen and (max-width: 820px) {
          font-size: 20px;
          line-height: 120%;
        }
        @media screen and (max-width: 500px) {
          margin-top: 20px;
        }
        width: 100%;
        max-width: 520px;
        font-weight: 500;
        font-size: 24px;
        line-height: 170%;
        letter-spacing: -0.02em;
        color: #5c5c5c;
        .ant-btn {
          @media screen and (max-width: 820px) {
            width: 150px !important;
            height: 40px !important;
            font-size: 12px;
            margin-top: 10px;
          }
          width: 215px !important;
          height: 55px !important;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 16px;
          margin-top: 30px;
        }
      }
    }
    &__grid {
      display: flex;
      margin-top: 80px;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 20px;
      @media screen and (max-width: 500px) {
        flex-direction: column;
      }
      &__text {
        @media screen and (max-width: 500px) {
          margin-top: 20px;
        }
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &__wrapper {
          max-width: 480px;
          width: 100%;
        }
        &__title {
          font-weight: 700;
          font-size: 36px;
          line-height: 150%;
          letter-spacing: -0.02em;
          @media screen and (max-width: 500px) {
            font-size: 24px;
            line-height: 150%;
          }
        }
        &__description {
          @media screen and (max-width: 500px) {
            font-size: 14px;
            line-height: 150%;
            margin-top: 20px;
          }
          margin-top: 30px;
          font-weight: 400;
          font-size: 18px;
          line-height: 170%;
          letter-spacing: -0.02em;
        }
      }
      &__image {
        @media screen and (max-width: 500px) {
          order: -1;
        }
        width: 100%;
        height: 100%;
        img {
          width: 100%;
        }
      }
    }
    &__bottom {
      display: flex;
      margin-top: 80px;
      align-items: center;
      width: 100%;
      background: #f2f2f2;
      margin-bottom: 80px;
      padding: 50px;
      @media screen and (max-width: 500px) {
        display: block;
        padding: 30px;
      }
      &__left {
        width: 100%;
        max-width: 600px;
        margin-right: auto;
        font-weight: 700;
        font-size: 36px;
        line-height: 150%;
        letter-spacing: -0.02em;
        @media screen and (max-width: 500px) {
          font-size: 20px;
          line-height: 120%;
          margin-bottom: 20px;
        }
      }
      &__right {
        .ant-btn {
          width: 162px;
          height: 55px;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 16px;
          line-height: 170%;
          letter-spacing: -0.02em;
          @media screen and (max-width: 500px) {
            width: 140px;
            height: 40px;
            font-size: 14px;
          }
        }
      }
    }
  }
  .ant-input {
    background: #f5f5f5;
    height: 50px;
    font-size: 18px;
  }
  .ant-input-affix-wrapper {
    padding: 0px 16px;
  }
  .admin-form.ant-form-vertical .ant-form-item-label > label {
    font-size: 18px;
  }
  &-checkbox {
    color: #5c5c5c;
    .ant-checkbox-wrapper {
      color: #5c5c5c !important;
      font-size: 18px !important;
      line-height: 170% !important;
      @media screen and (max-width: 500px) {
        font-size: 14px !important;
        line-height: 120% !important;
      }
    }
    .ant-checkbox-checked {
      background: #ff4307 !important;
    }
    .ant-form-item-control-input {
      border: none;
    }
    .ant-form-item-control-input-content {
      text-align: left;
    }
  }

  @media only screen and (max-width: @screen-lg) {
    height: auto;
  }

  &-login {
    color: #2b2b2b;
    min-height: calc(100vh - 160px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    &__wrapper {
      width: 100%;
      max-width: 560px;
      .anticon svg {
        color: #979797;
        width: 18px;
        height: 12px;
      }
    }
    &__bottom {
      margin-top: 30px;
      font-size: 18px;
      line-height: 170%;
      color: #5c5c5c;
      @media screen and (max-width: 500px) {
        font-size: 14px;
        line-height: 120%;
      }
    }
    &__button {
      margin-top: 50px;
      .ant-btn {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
        line-height: 170%;
      }
    }
    &__title {
      font-weight: 700;
      font-size: 36px;
      line-height: 150%;
      margin-bottom: 6px;
      @media screen and (max-width: 500px) {
        font-size: 28px;
        line-height: 120%;
      }
    }
    &__description {
      font-size: 18px;
      line-height: 170%;
      @media screen and (max-width: 500px) {
        font-size: 16px;
        line-height: 120%;
      }
      letter-spacing: -0.02em;
      margin-bottom: 30px;
      color: #5c5c5c;
    }
  }

  &-title {
    margin-bottom: 1.75rem !important;
    color: @white !important;
    text-align: center;
    font-size: 2.25rem !important;
    font-family: @montserrat;
  }

  &-first-blur {
    position: absolute;
    top: -600px;
    left: -1000px;
    z-index: 0;
  }

  &-second-blur {
    position: absolute;
    bottom: -600px;
    right: -900px;
    z-index: 0;
  }

  &-remember {
    color: #5c5c5c !important;
    a {
      color: #5c5c5c !important;
    }
    .ant-checkbox-wrapper,
    a {
      font-family: @montserrat;
      font-size: 15px;
      color: #5c5c5c !important;
      font-size: 18px !important;
      line-height: 170% !important;
      text-transform: capitalize !important;
    }

    &__right {
      text-align: right;
      color: #5c5c5c;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      a {
        @media screen and (max-width: 500px) {
          font-size: 14px !important;
          line-height: 120% !important;
        }
        color: #5c5c5c !important;
        font-size: 18px !important;
        line-height: 170% !important;
        text-transform: capitalize !important;
      }
    }
  }

  // .ant-input-affix-wrapper,
  // .ant-form-item-has-error
  //   :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  //   background: @dark-border !important;
  //   border-color: @dark-border;
  // }

  .ant-form-item-explain-error {
    text-align: left;
  }

  .ant-checkbox-inner {
    border-color: @dark-border;
    background: transparent;
  }

  .ant-input-prefix,
  .ant-input-password-icon {
    color: @white;
  }

  &-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: @gap-md;

    a {
      color: @white;
    }

    .logo {
      display: block;
      text-align: center;

      svg {
        display: block;
        width: 200px;

        @media only screen and (max-width: @screen-md) {
          width: 160px;
        }
      }
    }

    &__title {
      display: none;
      border-left: 1px solid @light-accent;
      padding: @gap-sm 0 @gap-sm @gap-md;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 1.15rem;
      color: @dark;
    }
  }

  &-container {
    max-width: 560px;
    margin: 0 auto;
  }

  &.auth--login {
    .auth-logo {
      padding: 0 @gap-md @gap-md @gap-lg;
      justify-content: flex-start;

      @media only screen and (max-width: @screen-md) {
        justify-content: center;
      }

      .logo {
        padding-right: @gap-md;
      }

      &__title {
        display: block;
        color: @white;

        @media only screen and (max-width: @screen-sm) {
          display: none;
        }
      }
    }
  }

  &-box {
    padding: 3rem 2.5rem;
    border-radius: 10px;
    background: @darkSecondary;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);

    .ant-typography {
      color: @dark;
    }

    &__recaptcha {
      display: flex;
      margin-bottom: 18px;
      &__body {
        margin: auto;
      }
    }

    &__instruction {
      margin-bottom: @gap-md;
      font-family: @montserrat;
      color: @white !important;
    }

    &__right {
      padding-left: @gap-lg;
      border-left: 1px solid @light-accent;

      @media only screen and (max-width: @screen-lg) {
        padding-top: @gap-md;
        padding-left: 0;
        border-left: none;
        border-top: 1px solid @dark-accent;
      }
    }

    h5.ant-typography {
      font-weight: 900;

      br {
        @media only screen and (max-width: @screen-lg) {
          display: none;
        }
      }
    }

    &__link,
    &__creator {
      margin-top: @gap-md;
      text-align: center;
      color: @white;
      font-family: @montserrat;

      a {
        color: @white;

        &:hover {
          color: @light-accent;
          text-decoration: underline;
        }
      }
    }

    &__creator {
      font-size: 1.125rem;
      cursor: pointer;
    }
  }

  // &-form {
  //   .ant-form-item {
  //     &-explain {
  //       font-size: 0.85rem;
  //     }

  //     &.height-auto {
  //       .ant-form-item-control-input {
  //         min-height: unset;
  //       }
  //     }

  //     .ant-btn[type='submit'] {
  //       padding: 0 4rem;
  //     }

  //     &:last-child {
  //       margin-bottom: 0;
  //     }
  //   }

  //   .ant-input-affix-wrapper {
  //     padding: @gap-sm;

  //     .ant-input-prefix {
  //       margin-right: @gap-sm;
  //     }
  //   }

  //   a {
  //     &:hover {
  //       text-decoration: underline;
  //     }
  //   }
  // }

  &-wrapper {
    padding-top: 110px;
    width: 100%;
  }

  .footer {
    position: absolute;
    padding: 2rem 0px;
    width: 100%;
    bottom: 0px;
  }
}

@media screen and (max-height: 768px) {
  .auth {
    &-wrapper {
      padding: 3rem 0;
      position: relative;
      padding-top: 110px;
      top: 0%;
      transform: translateY(0px);
    }
    .footer {
      position: relative;
    }
  }
}

@media screen and (max-width: @screen-md) {
  .auth {
    &-box {
      padding: 1.4rem;
      &__creator {
        font-size: 0.8rem;
      }
    }
    &-first-blur,
    &-second-blur {
      display: none;
    }
    &-title {
      font-size: 1.5rem !important;
      margin-bottom: 1rem !important;
    }
    .ant-form-item {
      margin-bottom: 1rem;
    }
    .ant-input-affix-wrapper {
      padding: 0.8rem;
    }
  }
}
