.ql-toolbar.ql-snow {
  border: 0;
  border-bottom: 1px solid #ccc;
  padding: 0 0 8px 0;
}

.ql-container {
  font-size: inherit;
  font-family: inherit;
  height: 300px;
}

.ql-container.ql-snow {
  border: 0;
}

.ql-editor {
  line-height: inherit;
  padding: 12px 0 0 0;
}

.ql-editor.ql-blank::before {
  left: 0;
  right: 0;
  font-style: inherit;
  color: #bfbfbf;
}

.ql-snow .ql-tooltip[data-mode='image']::before {
  content: 'Enter image:';
}

.ql-snow .ql-tooltip.ql-flip {
  left: 0 !important;
  top: -5rem !important;
  transform: translateY(0);
}

.ql-snow .ql-tooltip.ql-editing {
  left: 0 !important;
  top: -5rem !important;
  transform: translateY(0);
}
