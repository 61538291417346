.users {
  &-page-search {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  &-action {
    color: @dark !important;
    opacity: 0.5;
    cursor: pointer;
    @media screen and (min-width: @screen-sm) {
      font-size: 10px;
    }
  }
  &-modal {
    .ant-modal-body {
      padding: 40px 32px 32px;
    }
    &__icon {
      margin-bottom: 2rem;
      font-size: 70px;
    }
    &__desc {
      color: #1a1a1a80;
      &-revision {
        margin: -12px -8px -8px;
        text-align: left;
      }
    }
    &__button {
      font-size: 13px;
      padding: 13px 29.5px !important;
      line-height: 19px;
      height: auto;
      &-default {
        background: #f1f1f1;
        border-color: #f1f1f1;
        margin-left: 20px;
      }
      &-error {
        background: #d6222a;
        border-color: #d6222a;
        color: white;
      }
      &-success {
        background: #27ae60;
        border-color: #27ae60;
        color: white;
        &:hover {
          border-color: #27ae60;
          color: #27ae60;
        }
      }
    }
  }
  &-toolbar {
    .ant-input {
      border-radius: 0px;
      border-top: 1px solid #e1e1e1;
      border-left: 1px solid #e1e1e1;
      border-bottom: 1px solid #e1e1e1;
      border-right: none;
    }
    .ant-input:focus {
      border-radius: 0px;
      border-top: 1px solid #e1e1e1;
      border-left: 1px solid #e1e1e1;
      border-bottom: 1px solid #e1e1e1;
    }
    .ant-input:hover {
      border-radius: 0px;
      border-top: 1px solid #e1e1e1;
      border-left: 1px solid #e1e1e1;
      border-bottom: 1px solid #e1e1e1;
    }
    .ant-input-search
      > .ant-input-group
      > .ant-input-group-addon:last-child
      .ant-input-search-button {
      border-radius: 0px;
      border-top: 1px solid #e1e1e1;
      border-right: 1px solid #e1e1e1;
      border-bottom: 1px solid #e1e1e1;
    }
  }
}
