.navigation {
  padding: 2rem 0;
  transition-duration: 700ms;
  width: 100%;
  &-fixed {
    background: @dark;
    position: fixed;
    bottom: 100%;
    left: 0;
    transform: translateY(100%);
    z-index: 100;
  }
  &-scroll {
    position: relative;
    transform: translateY(0);
  }
  &__text {
    margin-bottom: 0px !important;
    margin-right: 1rem;
    color: @white !important;
    font-family: @montserrat;
  }
  &__right {
    text-align: right;
    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

@media screen and (max-width: @screen-md) {
  .navigation {
    &__text {
      display: none;
    }
    &__logo {
      width: 95%;
    }
  }
}
