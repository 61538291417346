.ant-avatar-group {
  display: inherit;
  .ant-avatar {
    border: none;
  }

  .ant-avatar:not(:first-child) {
    margin-left: 0;
  }
  .avatar-group-manager {
    margin-right: 4px;
  }
}

.video-modal {
  // fixed w-full z-50 top-0 left-0 hidden overflow-hidden text-center;
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  display: none;
  overflow: hidden;
  text-align: center;
  transition: 0.5s;
  height: 100vh;
  // background-color: @dark;
  background-color: rgba(26, 26, 26, 0.6);
  &-show {
    // @apply flex items-center justify-center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__wrapper {
    // @apply inline-flex items-center justify-center relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100vh;

    &__content {
      // @apply contents;
    }
  }

  &__image {
    // @apply inline-block;
    display: inline-block;
    max-height: 90%;
    max-width: 95%;
    .player {
      max-width: 95%;
      left: unset;
    }
  }
}
