.post {
  padding: 30px;
  background-color: #F8F8FA;
}
.post-featured {
  display: flex;
  margin-bottom: 15px;
}
.post-featured-text {
  font-weight: 700;
  font-size: 13px;
  margin-left: 6px;
}
.post-header-profile {
  display: flex;
  cursor: pointer;
}
.post-header-profile-detail {
  width: 100%;
}
.post-header-profile-detail img {
  width: 100%;
}
.post-header-profile-detail-name {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 14px;
  width: 100%;
  display: flex;
  align-items: center;
}
.post-header-profile-detail-name-left {
  margin-right: auto;
}
.post-header-profile-detail-name-right {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
}
.post-header-profile-detail-name-right-count {
  margin-left: 4px;
}
.post-header-profile-detail-time {
  font-size: 11px;
  color: rgba(26, 26, 26, 0.5);
}
.post-header-tools {
  margin-left: auto;
}
.post-header-tools-ellipsis {
  font-size: 16px;
  cursor: pointer;
}
.post-header-tools-menu {
  width: 130px;
}
.post-header-tools-menu-edit {
  color: rgba(26, 26, 26, 0.5);
}
.post-header-tools-menu-featured {
  color: rgba(26, 26, 26, 0.5);
}
.post-header-tools-menu-delete {
  color: #d6222a;
}
.post-body {
  margin-top: 20px;
}
.post-separator {
  width: 100%;
  height: 1px;
  background: #e1e1e1;
  margin-top: 20px;
  margin-bottom: 20px;
}
.post-separator-count-top {
  width: 100%;
  height: 1px;
  background: #e1e1e1;
  margin-top: 20px;
  margin-bottom: 12px;
}
.post-separator-count-bottom {
  width: 100%;
  height: 1px;
  background: #e1e1e1;
  margin-top: 10px;
  margin-bottom: 20px;
}
.post-response {
  display: flex;
}
.post-response-likes {
  display: flex;
}
.post-response-likes-icon {
  margin-right: 10px;
  cursor: pointer;
}
.post-response-likes-icon-owner {
  margin-right: 10px;
}
.post-response-comments {
  margin-left: auto;
}
.post-response-comments-event {
  margin-left: 5px;
  cursor: pointer;
}
.post-create-comment {
  display: flex;
}
.post-create-comment-input {
  width: 100%;
  margin-left: 10px;
}
.post-create-comment-input-editor {
  border: 1px solid #e1e1e1;
  margin-bottom: 10px;
}
.post-create-comment-input .ant-btn {
  height: 40px;
  width: 96px;
}
.post-create-comment .ql-toolbar {
  display: none;
}
.post-create-comment .ql-container {
  font-size: inherit;
  font-family: inherit;
  min-height: 65px;
  height: 100%;
}
.post-create-comment .ql-tooltip {
  left: -12px !important;
  top: 0px !important;
}
.post-create-comment .ql-toolbar.ql-snow {
  display: none;
}
.post-create-comment .ql-editor {
  padding-top: 2px;
}
.comment {
  margin-top: 20px;
  display: flex;
}
.comment-child {
  display: flex;
  margin-top: 20px;
}
.comment-child-wrapper {
  padding: 15px;
  width: 100%;
  background-color: #f1f1f1;
  border: 1px solid #e1e1e1;
}
.comment-child-wrapper-header {
  display: flex;
}
.comment-child-wrapper-header-left {
  font-size: 13px;
  font-weight: 700;
}
.comment-child-wrapper-header-left-time {
  margin-left: 5px;
  font-size: 11px;
  color: rgba(26, 26, 26, 0.5);
}
.comment-child-wrapper-header-right {
  margin-left: auto;
}
.comment-child-wrapper-header-right-menu {
  width: 130px;
}
.comment-child-wrapper-header-right-menu-reply {
  color: rgba(26, 26, 26, 0.5);
}
.comment-child-wrapper-header-right-menu-delete {
  color: #d6222a;
}
.comment-child-wrapper-body {
  margin-top: 10px;
}
.comment-padding {
  padding-left: 0.625rem;
  width: 100%;
}
.comment-create {
  display: flex;
  margin-top: 20px;
}
.comment-create-input {
  width: 100%;
  margin-left: 10px;
}
.comment-create-input-editor {
  border: 1px solid #e1e1e1;
  margin-bottom: 10px;
}
.comment-create-input .ant-btn {
  height: 40px;
  width: 96px;
}
.comment-create .ql-toolbar {
  display: none;
}
.comment-create .ql-container {
  font-size: inherit;
  font-family: inherit;
  min-height: 65px;
  height: 100%;
}
.comment-create .ql-toolbar.ql-snow {
  display: none;
}
.comment-create .ql-editor {
  padding-top: 2px;
}
.comment-create .ql-tooltip {
  left: -12px !important;
  top: 0px !important;
}
.comment-wrapper {
  padding: 15px;
  width: 100%;
  background-color: #f1f1f1;
  border: 1px solid #e1e1e1;
}
.comment-wrapper-header {
  display: flex;
}
.comment-wrapper-header-left {
  font-size: 13px;
  font-weight: 700;
}
.comment-wrapper-header-left-time {
  margin-left: 5px;
  font-size: 11px;
  color: rgba(26, 26, 26, 0.5);
}
.comment-wrapper-header-right {
  margin-left: auto;
}
.comment-wrapper-header-right-menu {
  width: 130px;
}
.comment-wrapper-header-right-menu-reply {
  color: rgba(26, 26, 26, 0.5);
}
.comment-wrapper-header-right-menu-delete {
  color: #d6222a;
}
.comment-wrapper-body {
  margin-top: 10px;
}
.post-delete-modal .ant-modal-close-x {
  font-size: 1rem;
}
.post-delete-modal .ant-modal-close-x .anticon {
  color: rgba(36, 41, 61, 0.5);
}
.post-delete-modal .ant-modal-close-x .anticon:hover {
  color: #24293D;
}
.post-delete-modal .post-delete-modal-container {
  padding: 32px;
}
.post-delete-modal .post-delete-modal-container-header {
  text-align: center;
}
.post-delete-modal .post-delete-modal-container-header-title {
  font-weight: 700;
  font-size: 20px;
  margin-top: 25px;
  margin-bottom: 20px;
}
.post-delete-modal .post-delete-modal-container-header-text {
  color: rgba(26, 26, 26, 0.5);
  text-align: center;
}
.post-delete-modal .post-delete-modal-container-footer {
  width: 100%;
  text-align: center;
}
.post-delete-modal .post-delete-modal-container-footer-delete {
  margin-right: 20px !important;
}
.post-delete-modal .post-delete-modal-container-footer .ant-btn {
  height: 40px;
  width: 100px;
  border-radius: 0px !important;
}
.post-featured-modal .ant-modal-close-x {
  font-size: 1rem;
}
.post-featured-modal .ant-modal-close-x .anticon {
  color: rgba(36, 41, 61, 0.5);
}
.post-featured-modal .ant-modal-close-x .anticon:hover {
  color: #24293D;
}
.post-featured-modal .post-featured-modal-container-header {
  text-align: center;
}
.post-featured-modal .post-featured-modal-container-header-title {
  font-weight: 700;
  font-size: 20px;
  margin-top: 25px;
  margin-bottom: 20px;
}
.post-featured-modal .post-featured-modal-container-header-text {
  color: rgba(26, 26, 26, 0.5);
  text-align: center;
}
.post-featured-modal .post-featured-modal-container-footer {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.post-featured-modal .post-featured-modal-container-footer-delete {
  margin-right: 20px !important;
}
.post-featured-modal .post-featured-modal-container-footer .ant-btn {
  height: 40px;
  width: 100px;
  border-radius: 0px !important;
}
.comment-delete-modal .ant-modal-close-x {
  font-size: 1rem;
}
.comment-delete-modal .ant-modal-close-x .anticon {
  color: rgba(36, 41, 61, 0.5);
}
.comment-delete-modal .ant-modal-close-x .anticon:hover {
  color: #24293D;
}
.comment-delete-modal .comment-delete-modal-container {
  padding: 32px;
}
.comment-delete-modal .comment-delete-modal-container-header {
  text-align: center;
}
.comment-delete-modal .comment-delete-modal-container-header-title {
  font-weight: 700;
  font-size: 20px;
  margin-top: 25px;
  margin-bottom: 20px;
}
.comment-delete-modal .comment-delete-modal-container-header-text {
  color: rgba(26, 26, 26, 0.5);
  text-align: center;
}
.comment-delete-modal .comment-delete-modal-container-footer {
  width: 100%;
  text-align: center;
}
.comment-delete-modal .comment-delete-modal-container-footer-delete {
  margin-right: 20px !important;
}
.comment-delete-modal .comment-delete-modal-container-footer .ant-btn {
  height: 40px;
  width: 100px;
  border-radius: 0px !important;
}
.post-update-modal .ant-modal-close-x {
  font-size: 1rem;
}
.post-update-modal .ant-modal-close-x .anticon {
  color: rgba(36, 41, 61, 0.5);
}
.post-update-modal .ant-modal-close-x .anticon:hover {
  color: #24293D;
}
.post-update-modal-input {
  width: 100%;
}
.post-update-modal-input-editor {
  border: 1px solid #e1e1e1;
  margin-bottom: 24px;
}
.post-update-modal-input .ant-btn {
  height: 40px;
  width: 96px;
}
.post-update-modal .ql-toolbar {
  display: none;
}
.post-update-modal .ql-container {
  font-size: inherit;
  font-family: inherit;
  min-height: 220px;
  height: 100%;
}
.post-update-modal .ql-tooltip {
  left: -12px !important;
  top: 0px !important;
}
.post-update-modal .ql-toolbar.ql-snow {
  display: none;
}
.post-update-modal .ql-editor {
  padding-top: 2px;
}
.post-update-modal .ant-btn {
  height: 40px;
  width: 94px;
}
.auth-header {
  background-color: #fbfbfb;
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 1;
  align-items: center;
  justify-content: center;
  height: 110px;
  border-bottom: 1px solid #e9e9e9;
}
.auth-header__body {
  max-width: 1300px;
  margin: 0px 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.auth-header__body__left {
  align-items: center;
  display: flex;
}
.auth-header__body__left img {
  width: 310px;
  height: 36px;
}
@media screen and (max-width: 500px) {
  .auth-header__body__left img {
    width: 200px;
    height: 24px;
  }
}
.auth-header__body__right {
  display: flex;
  gap: 16px;
  align-items: center;
}
.auth-header__body__right__text {
  font-size: 18px;
  line-height: 170%;
}
@media screen and (max-width: 768px) {
  .auth-header__body__right__text {
    display: none;
  }
}
.auth-header__body__right .ant-btn {
  border-radius: 4px;
  height: 47px;
  width: 122px;
}
.auth-footer {
  background: #2b2b2b;
  border-top: 1px solid #d9d9d9;
  color: white;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth-footer__wrapper {
  width: 100%;
  max-width: 1300px;
  margin: 0px 20px;
  display: flex;
  justify-content: space-between;
}
.auth-footer__left img {
  width: 310px;
  height: 36px;
}
@media screen and (max-width: 760px) {
  .auth-footer__left img {
    width: 200px;
    height: 24px;
  }
}
.auth-footer__left__text {
  font-size: 16px;
  line-height: 170%;
  margin-top: 6px;
}
@media screen and (max-width: 760px) {
  .auth-footer__left__text {
    font-size: 12px;
    line-height: 100%;
  }
}
.auth-footer__right {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 170%;
}
@media screen and (max-width: 760px) {
  .auth-footer__right {
    font-size: 12px;
    line-height: 100%;
  }
}
.auth-footer__right__visit {
  font-weight: 700;
  display: flex;
  color: white;
  align-items: center;
  gap: 10px;
  justify-content: right;
}
@media screen and (max-width: 500px) {
  .auth-footer__right__visit {
    gap: 5px;
  }
}
@media screen and (max-width: 500px) {
  .auth-footer__right__visit svg {
    height: 16px;
  }
}
.auth-footer__right__link {
  margin-top: 6px;
  display: flex;
  align-items: center;
  gap: 24px;
}
@media screen and (max-width: 760px) {
  .auth-footer__right__link {
    display: none;
  }
}
.auth-footer__right__link__item {
  color: white;
}
.patrons-modal {
  font-family: 'Satoshi', sans-serif;
}
.patrons-modal .ant-modal-close-icon svg {
  height: 18px;
  width: 18px;
}
.patrons-modal .ant-form-item-label > label {
  font-weight: 500;
  font-size: 18px;
  line-height: 170%;
  letter-spacing: -0.02em;
}
.patrons-modal .ant-input-password {
  padding: 10px 20px;
  font-size: 18px;
  line-height: 170%;
  background: #f5f5f5;
}
.patrons-modal .ant-input {
  padding: 10px 20px;
  font-size: 18px;
  line-height: 170%;
  background: #f5f5f5;
}
.patrons-modal__title {
  font-weight: 700;
  font-size: 36px;
  line-height: 150%;
  text-align: center;
}
.patrons-modal__description {
  font-size: 18px;
  line-height: 170%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #5c5c5c;
  width: 100%;
  max-width: 480px;
  margin-top: 6px;
}
.patrons-modal__form {
  margin-top: 20px;
}
.patrons-modal__form__flex {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
@media screen and (max-width: 600px) {
  .patrons-modal__form__flex {
    display: block;
  }
}
.patrons-modal__form__flex__item {
  width: 100%;
}
.patrons-modal__button {
  margin-top: 30px;
}
.patrons-modal__button .ant-btn {
  width: 100% !important;
  height: 55px !important;
}
.patrons-modal__success__title {
  font-weight: 700;
  font-size: 26px;
  line-height: 150%;
  letter-spacing: -0.02em;
  text-align: center;
}
.patrons-modal__success__description {
  font-size: 18px;
  line-height: 170%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #5c5c5c;
  width: 445px;
  margin-top: 26px;
  margin-bottom: 50px;
}
.patrons-modal__register .ant-form-item-control {
  height: fit-content;
}
.patrons-modal__register .ant-form-item-required::before {
  display: none !important;
}
.patrons-modal__register__label {
  font-weight: 500;
  font-size: 18px;
  line-height: 170%;
  letter-spacing: -0.02em;
  margin-bottom: 8px;
}
.patrons-modal__register__interested {
  width: 100%;
  display: flex;
  margin-bottom: 24px;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
}
.patrons-modal__register__interested .interested__active {
  border: 1px solid #2b2b2b !important;
}
.patrons-modal__register__interested__option {
  text-transform: capitalize;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 9.5px 0px;
  border: 1px solid #e0e0e0;
  color: #979797;
  font-size: 14px;
  color: #1a1a1a;
  line-height: 170%;
  letter-spacing: -0.02em;
}
.patrons-modal__register__interested__right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.patrons-modal__register__interested__left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.auth {
  min-height: 100vh;
  font-family: 'Satoshi', sans-serif;
  background-color: #fbfbfb;
}
.auth .ant-form-item-control-input {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
}
.auth-forgot {
  color: #2b2b2b;
  min-height: calc(100vh - 160px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.auth-forgot__wrapper {
  width: 100%;
  max-width: 560px;
}
.auth-forgot__title {
  font-weight: 700;
  font-size: 36px;
  line-height: 150%;
  margin-bottom: 30px;
}
@media screen and (max-width: 500px) {
  .auth-forgot__title {
    font-size: 28px;
    line-height: 120%;
  }
}
.auth-forgot__button .ant-btn {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  line-height: 170%;
  height: 55px;
}
.auth-reset {
  color: #2b2b2b;
  min-height: calc(100vh - 160px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.auth-reset__wrapper {
  width: 100%;
  max-width: 560px;
}
.auth-reset__description {
  font-weight: 400;
  font-size: 18px;
  line-height: 170%;
  letter-spacing: -0.02em;
  margin-bottom: 20px;
}
@media screen and (max-width: 500px) {
  .auth-reset__description {
    font-size: 14px;
    line-height: 150%;
    margin-top: 20px;
  }
}
.auth-reset__title {
  font-weight: 700;
  font-size: 36px;
  line-height: 150%;
  margin-bottom: 6px;
}
@media screen and (max-width: 500px) {
  .auth-reset__title {
    font-size: 28px;
    line-height: 120%;
  }
}
.auth-reset__button .ant-btn {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  line-height: 170%;
  height: 55px;
}
.auth-invitation {
  color: #2b2b2b;
  min-height: calc(100vh - 160px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.auth-invitation__wrapper {
  width: 100%;
  max-width: 560px;
}
.auth-invitation__description {
  font-weight: 400;
  font-size: 18px;
  line-height: 170%;
  letter-spacing: -0.02em;
  margin-bottom: 20px;
}
@media screen and (max-width: 500px) {
  .auth-invitation__description {
    font-size: 14px;
    line-height: 150%;
    margin-top: 20px;
  }
}
.auth-invitation__title {
  font-weight: 700;
  font-size: 36px;
  line-height: 150%;
  margin-bottom: 6px;
}
@media screen and (max-width: 500px) {
  .auth-invitation__title {
    font-size: 28px;
    line-height: 120%;
  }
}
.auth-invitation__button .ant-btn {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  line-height: 170%;
  height: 55px;
  width: 100%;
}
.auth-join {
  color: #2b2b2b;
  min-height: calc(100vh - 160px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.auth-join__wrapper {
  width: 100%;
  max-width: 560px;
}
.auth-join__description {
  font-weight: 400;
  font-size: 18px;
  line-height: 170%;
  letter-spacing: -0.02em;
  margin-bottom: 20px;
}
@media screen and (max-width: 500px) {
  .auth-join__description {
    font-size: 14px;
    line-height: 150%;
    margin-top: 20px;
  }
}
.auth-join__title {
  font-weight: 700;
  font-size: 36px;
  line-height: 150%;
  margin-bottom: 6px;
}
@media screen and (max-width: 500px) {
  .auth-join__title {
    font-size: 28px;
    line-height: 120%;
  }
}
.auth-join__button .ant-btn {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  line-height: 170%;
  height: 55px;
  width: 100%;
}
.auth-landing {
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth-landing .ant-btn {
  border-radius: 4px;
}
.auth-landing__wrapper {
  width: 100%;
  max-width: 1300px;
  margin: 0px 20px;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 500px) {
  .auth-landing__wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.auth-landing__image {
  margin-top: 124px;
}
.auth-landing__image img {
  width: 100%;
}
.auth-landing__top {
  display: flex;
  margin-top: 100px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}
@media screen and (max-width: 500px) {
  .auth-landing__top {
    display: block;
    margin-top: 80px;
  }
}
.auth-landing__top__left {
  width: 100%;
  max-width: 520px;
  font-weight: 700;
  font-size: 36px;
  line-height: 150%;
  letter-spacing: -0.02em;
}
@media screen and (max-width: 820px) {
  .auth-landing__top__left {
    font-size: 28px;
    line-height: 120%;
  }
}
.auth-landing__top__right {
  width: 100%;
  max-width: 520px;
  font-weight: 500;
  font-size: 24px;
  line-height: 170%;
  letter-spacing: -0.02em;
  color: #5c5c5c;
}
@media screen and (max-width: 820px) {
  .auth-landing__top__right {
    font-size: 20px;
    line-height: 120%;
  }
}
@media screen and (max-width: 500px) {
  .auth-landing__top__right {
    margin-top: 20px;
  }
}
.auth-landing__top__right .ant-btn {
  width: 215px !important;
  height: 55px !important;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  margin-top: 30px;
}
@media screen and (max-width: 820px) {
  .auth-landing__top__right .ant-btn {
    width: 150px !important;
    height: 40px !important;
    font-size: 12px;
    margin-top: 10px;
  }
}
.auth-landing__grid {
  display: flex;
  margin-top: 80px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}
@media screen and (max-width: 500px) {
  .auth-landing__grid {
    flex-direction: column;
  }
}
.auth-landing__grid__text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 500px) {
  .auth-landing__grid__text {
    margin-top: 20px;
  }
}
.auth-landing__grid__text__wrapper {
  max-width: 480px;
  width: 100%;
}
.auth-landing__grid__text__title {
  font-weight: 700;
  font-size: 36px;
  line-height: 150%;
  letter-spacing: -0.02em;
}
@media screen and (max-width: 500px) {
  .auth-landing__grid__text__title {
    font-size: 24px;
    line-height: 150%;
  }
}
.auth-landing__grid__text__description {
  margin-top: 30px;
  font-weight: 400;
  font-size: 18px;
  line-height: 170%;
  letter-spacing: -0.02em;
}
@media screen and (max-width: 500px) {
  .auth-landing__grid__text__description {
    font-size: 14px;
    line-height: 150%;
    margin-top: 20px;
  }
}
.auth-landing__grid__image {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 500px) {
  .auth-landing__grid__image {
    order: -1;
  }
}
.auth-landing__grid__image img {
  width: 100%;
}
.auth-landing__bottom {
  display: flex;
  margin-top: 80px;
  align-items: center;
  width: 100%;
  background: #f2f2f2;
  margin-bottom: 80px;
  padding: 50px;
}
@media screen and (max-width: 500px) {
  .auth-landing__bottom {
    display: block;
    padding: 30px;
  }
}
.auth-landing__bottom__left {
  width: 100%;
  max-width: 600px;
  margin-right: auto;
  font-weight: 700;
  font-size: 36px;
  line-height: 150%;
  letter-spacing: -0.02em;
}
@media screen and (max-width: 500px) {
  .auth-landing__bottom__left {
    font-size: 20px;
    line-height: 120%;
    margin-bottom: 20px;
  }
}
.auth-landing__bottom__right .ant-btn {
  width: 162px;
  height: 55px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  line-height: 170%;
  letter-spacing: -0.02em;
}
@media screen and (max-width: 500px) {
  .auth-landing__bottom__right .ant-btn {
    width: 140px;
    height: 40px;
    font-size: 14px;
  }
}
.auth .ant-input {
  background: #f5f5f5;
  height: 50px;
  font-size: 18px;
}
.auth .ant-input-affix-wrapper {
  padding: 0px 16px;
}
.auth .admin-form.ant-form-vertical .ant-form-item-label > label {
  font-size: 18px;
}
.auth-checkbox {
  color: #5c5c5c;
}
.auth-checkbox .ant-checkbox-wrapper {
  color: #5c5c5c !important;
  font-size: 18px !important;
  line-height: 170% !important;
}
@media screen and (max-width: 500px) {
  .auth-checkbox .ant-checkbox-wrapper {
    font-size: 14px !important;
    line-height: 120% !important;
  }
}
.auth-checkbox .ant-checkbox-checked {
  background: #ff4307 !important;
}
.auth-checkbox .ant-form-item-control-input {
  border: none;
}
.auth-checkbox .ant-form-item-control-input-content {
  text-align: left;
}
@media only screen and (max-width: 992px) {
  .auth {
    height: auto;
  }
}
.auth-login {
  color: #2b2b2b;
  min-height: calc(100vh - 160px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.auth-login__wrapper {
  width: 100%;
  max-width: 560px;
}
.auth-login__wrapper .anticon svg {
  color: #979797;
  width: 18px;
  height: 12px;
}
.auth-login__bottom {
  margin-top: 30px;
  font-size: 18px;
  line-height: 170%;
  color: #5c5c5c;
}
@media screen and (max-width: 500px) {
  .auth-login__bottom {
    font-size: 14px;
    line-height: 120%;
  }
}
.auth-login__button {
  margin-top: 50px;
}
.auth-login__button .ant-btn {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  line-height: 170%;
}
.auth-login__title {
  font-weight: 700;
  font-size: 36px;
  line-height: 150%;
  margin-bottom: 6px;
}
@media screen and (max-width: 500px) {
  .auth-login__title {
    font-size: 28px;
    line-height: 120%;
  }
}
.auth-login__description {
  font-size: 18px;
  line-height: 170%;
  letter-spacing: -0.02em;
  margin-bottom: 30px;
  color: #5c5c5c;
}
@media screen and (max-width: 500px) {
  .auth-login__description {
    font-size: 16px;
    line-height: 120%;
  }
}
.auth-title {
  margin-bottom: 1.75rem !important;
  color: #ffffff !important;
  text-align: center;
  font-size: 2.25rem !important;
  font-family: Montserrat;
}
.auth-first-blur {
  position: absolute;
  top: -600px;
  left: -1000px;
  z-index: 0;
}
.auth-second-blur {
  position: absolute;
  bottom: -600px;
  right: -900px;
  z-index: 0;
}
.auth-remember {
  color: #5c5c5c !important;
}
.auth-remember a {
  color: #5c5c5c !important;
}
.auth-remember .ant-checkbox-wrapper,
.auth-remember a {
  font-family: Montserrat;
  font-size: 15px;
  color: #5c5c5c !important;
  font-size: 18px !important;
  line-height: 170% !important;
  text-transform: capitalize !important;
}
.auth-remember__right {
  text-align: right;
  color: #5c5c5c;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.auth-remember__right a {
  color: #5c5c5c !important;
  font-size: 18px !important;
  line-height: 170% !important;
  text-transform: capitalize !important;
}
@media screen and (max-width: 500px) {
  .auth-remember__right a {
    font-size: 14px !important;
    line-height: 120% !important;
  }
}
.auth .ant-form-item-explain-error {
  text-align: left;
}
.auth .ant-checkbox-inner {
  border-color: #4A4A4A;
  background: transparent;
}
.auth .ant-input-prefix,
.auth .ant-input-password-icon {
  color: #ffffff;
}
.auth-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
}
.auth-logo a {
  color: #ffffff;
}
.auth-logo .logo {
  display: block;
  text-align: center;
}
.auth-logo .logo svg {
  display: block;
  width: 200px;
}
@media only screen and (max-width: 768px) {
  .auth-logo .logo svg {
    width: 160px;
  }
}
.auth-logo__title {
  display: none;
  border-left: 1px solid #E9E9EB;
  padding: 0.625rem 0 0.625rem 1rem;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.15rem;
  color: #1A1A1A;
}
.auth-container {
  max-width: 560px;
  margin: 0 auto;
}
.auth.auth--login .auth-logo {
  padding: 0 1rem 1rem 1.875rem;
  justify-content: flex-start;
}
@media only screen and (max-width: 768px) {
  .auth.auth--login .auth-logo {
    justify-content: center;
  }
}
.auth.auth--login .auth-logo .logo {
  padding-right: 1rem;
}
.auth.auth--login .auth-logo__title {
  display: block;
  color: #ffffff;
}
@media only screen and (max-width: 576px) {
  .auth.auth--login .auth-logo__title {
    display: none;
  }
}
.auth-box {
  padding: 3rem 2.5rem;
  border-radius: 10px;
  background: #2B2B2B;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
}
.auth-box .ant-typography {
  color: #1A1A1A;
}
.auth-box__recaptcha {
  display: flex;
  margin-bottom: 18px;
}
.auth-box__recaptcha__body {
  margin: auto;
}
.auth-box__instruction {
  margin-bottom: 1rem;
  font-family: Montserrat;
  color: #ffffff !important;
}
.auth-box__right {
  padding-left: 1.875rem;
  border-left: 1px solid #E9E9EB;
}
@media only screen and (max-width: 992px) {
  .auth-box__right {
    padding-top: 1rem;
    padding-left: 0;
    border-left: none;
    border-top: 1px solid #383E54;
  }
}
.auth-box h5.ant-typography {
  font-weight: 900;
}
@media only screen and (max-width: 992px) {
  .auth-box h5.ant-typography br {
    display: none;
  }
}
.auth-box__link,
.auth-box__creator {
  margin-top: 1rem;
  text-align: center;
  color: #ffffff;
  font-family: Montserrat;
}
.auth-box__link a,
.auth-box__creator a {
  color: #ffffff;
}
.auth-box__link a:hover,
.auth-box__creator a:hover {
  color: #E9E9EB;
  text-decoration: underline;
}
.auth-box__creator {
  font-size: 1.125rem;
  cursor: pointer;
}
.auth-wrapper {
  padding-top: 110px;
  width: 100%;
}
.auth .footer {
  position: absolute;
  padding: 2rem 0px;
  width: 100%;
  bottom: 0px;
}
@media screen and (max-height: 768px) {
  .auth-wrapper {
    padding: 3rem 0;
    position: relative;
    padding-top: 110px;
    top: 0%;
    transform: translateY(0px);
  }
  .auth .footer {
    position: relative;
  }
}
@media screen and (max-width: 768px) {
  .auth-box {
    padding: 1.4rem;
  }
  .auth-box__creator {
    font-size: 0.8rem;
  }
  .auth-first-blur,
  .auth-second-blur {
    display: none;
  }
  .auth-title {
    font-size: 1.5rem !important;
    margin-bottom: 1rem !important;
  }
  .auth .ant-form-item {
    margin-bottom: 1rem;
  }
  .auth .ant-input-affix-wrapper {
    padding: 0.8rem;
  }
}
.fanclub-page-header {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}
.fanclub-page-header-detail {
  cursor: pointer;
  font-size: 18px;
  margin-left: 6px;
  font-weight: 700;
  margin-bottom: 30px;
}
.fanclub-page-body-load-post {
  display: flex;
  margin-top: 20px;
  width: 100%;
}
.fanclub-page-body-load-post-button {
  margin: auto;
}
.fanclub-page-body-post {
  padding: 30px;
  margin-top: 30px;
  background-color: #F8F8FA;
}
.fanclub-page-body-post-header {
  display: flex;
}
.fanclub-page-body-post-header-profile {
  display: flex;
}
.fanclub-page-body-post-header-profile-detail {
  padding-left: 0.625rem;
}
.fanclub-page-body-post-header-profile-detail-name {
  font-size: 13px;
  font-weight: 700;
}
.fanclub-page-body-post-header-profile-detail-time {
  font-size: 11px;
  color: rgba(26, 26, 26, 0.5);
}
.fanclub-page-body-post-header-tools {
  margin-left: auto;
  cursor: pointer;
}
.fanclub-page-body-post-header-tools-menu {
  width: 130px;
}
.fanclub-page-body-post-header-tools-menu-edit {
  color: rgba(26, 26, 26, 0.5);
}
.fanclub-page-body-post-header-tools-menu-delete {
  color: #d6222a;
}
.fanclub-page-body-post-body {
  margin-top: 20px;
}
.fanclub-page-body-post-separator {
  width: 100%;
  height: 1px;
  background: #e1e1e1;
  margin-top: 10px;
  margin-bottom: 10px;
}
.fanclub-page-body-post-response {
  display: flex;
}
.fanclub-page-body-post-response-likes {
  display: flex;
}
.fanclub-page-body-post-response-likes-icon {
  margin-right: 10px;
}
.fanclub-page-body-post-response-comments {
  margin-left: auto;
}
.fanclub-page-body-post-comment {
  display: flex;
}
.fanclub-page-body-post-comment-child {
  display: flex;
  margin-top: 20px;
}
.fanclub-page-body-post-comment-child-wrapper {
  padding: 15px;
  width: 100%;
  background-color: #f1f1f1;
  border: 1px solid #e1e1e1;
}
.fanclub-page-body-post-comment-child-wrapper-header {
  display: flex;
}
.fanclub-page-body-post-comment-child-wrapper-header-left {
  font-size: 13px;
  font-weight: 700;
}
.fanclub-page-body-post-comment-child-wrapper-header-left-time {
  font-size: 11px;
  color: rgba(26, 26, 26, 0.5);
}
.fanclub-page-body-post-comment-child-wrapper-header-right {
  margin-left: auto;
}
.fanclub-page-body-post-comment-child-wrapper-header-right-menu {
  width: 130px;
}
.fanclub-page-body-post-comment-child-wrapper-header-right-menu-reply {
  color: rgba(26, 26, 26, 0.5);
}
.fanclub-page-body-post-comment-child-wrapper-header-right-menu-delete {
  color: #d6222a;
}
.fanclub-page-body-post-comment-child-wrapper-body {
  margin-top: 10px;
}
.fanclub-page-body-post-comment-padding {
  padding-left: 0.625rem;
}
.fanclub-page-body-post-comment-wrapper {
  padding: 15px;
  width: 100%;
  background-color: #f1f1f1;
  border: 1px solid #e1e1e1;
}
.fanclub-page-body-post-comment-wrapper-header {
  display: flex;
}
.fanclub-page-body-post-comment-wrapper-header-left {
  font-size: 13px;
  font-weight: 700;
}
.fanclub-page-body-post-comment-wrapper-header-left-time {
  font-size: 11px;
  color: rgba(26, 26, 26, 0.5);
}
.fanclub-page-body-post-comment-wrapper-header-right {
  margin-left: auto;
}
.fanclub-page-body-post-comment-wrapper-header-right-menu {
  width: 130px;
}
.fanclub-page-body-post-comment-wrapper-header-right-menu-reply {
  color: rgba(26, 26, 26, 0.5);
}
.fanclub-page-body-post-comment-wrapper-header-right-menu-delete {
  color: #d6222a;
}
.fanclub-page-body-post-comment-wrapper-body {
  margin-top: 10px;
}
.fanclub-page-body-post-create-comment {
  display: flex;
  padding: 30px;
  background-color: #F8F8FA;
}
.fanclub-page-body-post-create-comment-input {
  width: 100%;
  margin-left: 10px;
}
.fanclub-page-body-post-create-comment-input-editor {
  border: 1px solid #e1e1e1;
  margin-bottom: 10px;
}
.fanclub-page-body-post-create-comment-input .ant-btn {
  height: 40px;
  width: 160px;
}
.fanclub-page-body-post-create-comment .ql-container {
  font-size: inherit;
  font-family: inherit;
  min-height: 65px;
  height: 100%;
}
.fanclub-page-body-post-create-comment .ql-tooltip {
  left: -12px !important;
  top: 0px !important;
}
.fanclub-page-body-create-post {
  display: flex;
  padding: 30px;
  background-color: #F8F8FA;
}
.fanclub-page-body-create-post-input {
  width: 100%;
  margin-left: 10px;
}
.fanclub-page-body-create-post-input-editor {
  border: 1px solid #e1e1e1;
  margin-bottom: 10px;
}
.fanclub-page-body-create-post-input .ant-btn {
  height: 40px;
  width: 160px;
}
.fanclub-page-body-create-post .ql-toolbar.ql-snow {
  display: none;
}
.fanclub-page-body-create-post .ql-editor {
  padding-top: 2px;
}
.fanclub-page-body-create-post .ql-container {
  font-size: inherit;
  font-family: inherit;
  min-height: 65px;
  height: 100%;
}
.fanclub-page-body-create-post .ql-tooltip {
  left: -12px !important;
  top: 0px !important;
}
.fanclub-page-body-channel {
  padding-left: 30px;
}
@media screen and (max-width: 992px) {
  .fanclub-page-body-channel {
    padding-left: 0px;
    margin-top: 20px;
  }
}
.fanclub-page-body-channel-content {
  background-color: #F8F8FA;
  padding-bottom: 25px;
}
.fanclub-page-body-channel-content-header {
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 15px;
  font-size: 20px;
  font-weight: 700;
}
.fanclub-page-body-channel-content-list-item {
  cursor: pointer;
  padding: 10px 30px;
}
.fanclub-page-body-channel-content-list-item-active {
  cursor: pointer;
  padding: 10px 30px;
  background-color: #f1f1f1;
}
.fanclub-page-body-channel-content-list-item-active-left-name {
  font-size: 13px;
  font-weight: 700;
}
.fanclub-page-body-channel-content-list-item-active-left-activity {
  font-size: 10px;
}
.fanclub-page-body-channel-content-list-item-active-right {
  text-align: right;
}
.fanclub-page-body-channel-content-list-item-left-name {
  font-size: 13px;
  font-weight: 700;
}
.fanclub-page-body-channel-content-list-item-left-activity {
  font-size: 10px;
}
.fanclub-page-body-channel-content-list-item-right {
  text-align: right;
}
.fanclub-page-body-channel-content-list-item:hover {
  background-color: #f1f1f1;
}
.fanclub-page-body-channel-button {
  width: 100%;
}
.fanclub-page-body-channel .ant-btn {
  margin-top: 30px;
  height: 40px;
}
.fanclub-channel-created-modal .ant-modal-close-x {
  font-size: 1rem;
}
.fanclub-channel-created-modal .ant-modal-close-x .anticon {
  color: rgba(36, 41, 61, 0.5);
}
.fanclub-channel-created-modal .ant-modal-close-x .anticon:hover {
  color: #24293D;
}
.fanclub-channel-created-modal .fanclub-channel-created-modal-container {
  padding: 32px;
}
.fanclub-channel-created-modal .fanclub-channel-created-modal-container-header {
  text-align: center;
}
.fanclub-channel-created-modal .fanclub-channel-created-modal-container-header-title {
  font-weight: 700;
  font-size: 20px;
  margin-top: 25px;
  margin-bottom: 20px;
}
.fanclub-channel-created-modal .fanclub-channel-created-modal-container-header-text {
  color: rgba(26, 26, 26, 0.5);
  text-align: center;
}
.fanclub-channel-delete-modal .ant-modal-close-x {
  font-size: 1rem;
}
.fanclub-channel-delete-modal .ant-modal-close-x .anticon {
  color: rgba(36, 41, 61, 0.5);
}
.fanclub-channel-delete-modal .ant-modal-close-x .anticon:hover {
  color: #24293D;
}
.fanclub-channel-delete-modal .fanclub-channel-delete-modal-container {
  padding: 32px;
}
.fanclub-channel-delete-modal .fanclub-channel-delete-modal-container-header {
  text-align: center;
}
.fanclub-channel-delete-modal .fanclub-channel-delete-modal-container-header-title {
  font-weight: 700;
  font-size: 20px;
  margin-top: 25px;
  margin-bottom: 20px;
}
.fanclub-channel-delete-modal .fanclub-channel-delete-modal-container-header-text {
  color: rgba(26, 26, 26, 0.5);
  text-align: center;
}
.fanclub-channel-delete-modal .fanclub-channel-delete-modal-container-footer {
  width: 100%;
  text-align: center;
}
.fanclub-channel-delete-modal .fanclub-channel-delete-modal-container-footer-delete {
  margin-right: 20px !important;
}
.fanclub-channel-delete-modal .fanclub-channel-delete-modal-container-footer .ant-btn {
  height: 40px;
  width: 100px;
  border-radius: 0px !important;
}
.fanclub-channel-update-modal .ant-modal-close-x {
  font-size: 1rem;
}
.fanclub-channel-update-modal .ant-modal-close-x .anticon {
  color: rgba(36, 41, 61, 0.5);
}
.fanclub-channel-update-modal .ant-modal-close-x .anticon:hover {
  color: #24293D;
}
.fanclub-channel-update-modal-private {
  margin-bottom: 24px;
  width: 100%;
  display: flex;
}
.fanclub-channel-update-modal-private-left-description {
  color: rgba(26, 26, 26, 0.5);
  max-width: 345px;
  margin-top: 5px;
}
.fanclub-channel-update-modal-private-right {
  margin-top: 5px;
  margin-left: auto;
}
.fanclub-channel-update-modal .admin-form .ant-btn {
  padding: 0.5rem 2rem;
}
.fanclub-upload-image .ant-upload.ant-upload-drag {
  height: 200px;
  width: 200px;
}
.fanclub-upload-image .fanclub-dragger {
  display: inline-block;
}
.fanclub-upload-image .fanclub-image-container {
  position: relative;
  display: inline-block;
}
.fanclub-upload-image .fanclub-image-remove {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.5rem !important;
}
.waitlist-page-search {
  margin-top: 40px;
  margin-bottom: 20px;
}
.waitlist-action {
  color: #1A1A1A !important;
  opacity: 0.5;
  cursor: pointer;
}
@media screen and (min-width: 576px) {
  .waitlist-action {
    font-size: 10px;
  }
}
.waitlist-toolbar-right {
  text-align: right;
}
@media screen and (max-width: 576px) {
  .waitlist-toolbar-right {
    text-align: left;
    margin-top: 10px;
  }
}
.waitlist-toolbar .ant-input {
  border-radius: 0px;
  border-top: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  border-right: none;
}
.waitlist-toolbar .ant-input:focus {
  border-radius: 0px;
  border-top: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}
.waitlist-toolbar .ant-input:hover {
  border-radius: 0px;
  border-top: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}
.waitlist-toolbar .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  border-radius: 0px;
  border-top: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}
.inbox-toolbar .toolbar-search .ant-select {
  min-width: 164px;
}
@media screen and (max-width: 767px) {
  .inbox-toolbar .toolbar-search .ant-select {
    min-width: 80px;
  }
}
.inbox-toolbar-search {
  min-width: 255px;
}
@media screen and (max-width: 767px) {
  .inbox-toolbar-search {
    min-width: 40%;
  }
}
.inbox-toolbar-search .toolbar-search {
  max-width: unset;
}
.inbox-id {
  display: flex;
  align-items: center;
}
.inbox-id-unread {
  margin-left: 8px;
  width: 8px;
  height: 8px;
  border-radius: 100px;
  background: #0077f8;
}
.inbox-submitted-date {
  display: flex;
  align-items: center;
  gap: 12px;
}
.inbox-page-message {
  max-width: 250px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.inbox-page-search {
  margin-top: 40px;
  margin-bottom: 20px;
}
.inbox-page-search-readAll .ant-btn {
  height: 40px;
}
.inbox-page-search-readAll .ant-btn > span {
  display: unset;
  padding: 12px 16px;
}
.inbox-action {
  color: #1A1A1A !important;
  opacity: 0.5;
  cursor: pointer;
}
@media screen and (min-width: 576px) {
  .inbox-action {
    font-size: 10px;
  }
}
.inbox-toolbar-right {
  display: flex;
  align-items: center;
  justify-content: right;
}
@media screen and (max-width: 767px) {
  .inbox-toolbar-right {
    justify-content: left;
    margin-top: 8px;
  }
}
.inbox-toolbar-right-wrapper {
  display: flex;
  align-items: center;
  align-items: right;
  gap: 10px;
}
@media screen and (max-width: 767px) {
  .inbox-toolbar-right-wrapper {
    display: flex;
  }
}
.inbox-toolbar .ant-input {
  border-radius: 0px;
  border-top: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  border-right: none;
}
.inbox-toolbar .ant-input:focus {
  border-radius: 0px;
  border-top: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}
.inbox-toolbar .ant-input:hover {
  border-radius: 0px;
  border-top: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}
.inbox-toolbar .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  border-radius: 0px;
  border-top: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}
.inbox-modal .ant-modal-body {
  padding-top: 12px;
}
.inbox-modal .ant-modal-close-x {
  font-size: 1rem;
}
.inbox-modal .ant-modal-close-x .anticon {
  color: rgba(36, 41, 61, 0.5);
}
.inbox-modal .ant-modal-close-x .anticon:hover {
  color: #24293D;
}
.inbox-modal .inbox-modal-container-header {
  text-align: center;
}
.inbox-modal .inbox-modal-container-header-title {
  font-weight: 700;
  font-size: 20px;
  margin-top: 25px;
  margin-bottom: 20px;
}
.inbox-modal .inbox-modal-container-header-text {
  color: rgba(26, 26, 26, 0.5);
  text-align: center;
}
.inbox-modal .inbox-modal-container-detail-table {
  width: 100%;
  font-size: 15px;
}
.inbox-modal .inbox-modal-container-detail-table-row {
  border-bottom: 1px solid #f1f1f1;
  line-height: 50px;
}
.inbox-modal .inbox-modal-container-detail-table-row-left {
  width: 30%;
  font-weight: 700;
}
.inbox-modal .inbox-modal-container-detail-table-row-avatar {
  display: flex;
}
.inbox-modal .inbox-modal-container-detail-table-row-avatar .avatar-name {
  margin-right: 0.625rem;
}
.inbox-modal .inbox-modal-container-detail-table-row-last {
  line-height: 50px;
}
.inbox-modal .inbox-modal-container-detail-table-row-last-left {
  width: 30%;
  font-weight: 700;
}
.inbox-modal .inbox-modal-container-detail-table-row-last-avatar {
  display: flex;
}
.inbox-modal .inbox-modal-container-detail-table-row-last-avatar .avatar-name {
  margin-right: 0.625rem;
}
.inbox-modal .inbox-modal-container-footer {
  width: 100%;
  margin-top: 20px;
  text-align: center;
}
.inbox-modal .inbox-modal-container-footer-approve {
  margin-right: 20px !important;
}
.inbox-modal .inbox-modal-container-footer .ant-btn {
  border-radius: 0px !important;
}
.inbox-delete-modal .ant-modal-close-x {
  font-size: 1rem;
}
.inbox-delete-modal .ant-modal-close-x .anticon {
  color: rgba(36, 41, 61, 0.5);
}
.inbox-delete-modal .ant-modal-close-x .anticon:hover {
  color: #24293D;
}
.inbox-delete-modal .inbox-delete-modal-container {
  padding: 32px;
}
.inbox-delete-modal .inbox-delete-modal-container-header {
  text-align: center;
}
.inbox-delete-modal .inbox-delete-modal-container-header-title {
  font-weight: 700;
  font-size: 20px;
  margin-top: 25px;
  margin-bottom: 20px;
}
.inbox-delete-modal .inbox-delete-modal-container-header-text {
  color: rgba(26, 26, 26, 0.5);
  text-align: center;
}
.inbox-delete-modal .inbox-delete-modal-container-footer {
  width: 100%;
  text-align: center;
}
.inbox-delete-modal .inbox-delete-modal-container-footer-delete {
  margin-right: 20px !important;
}
.inbox-delete-modal .inbox-delete-modal-container-footer .ant-btn {
  height: 40px;
  width: 100px;
  border-radius: 0px !important;
}
.gallery-experience-page .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: none;
}
.gallery-experience-page .admin-form .ant-btn {
  padding: 12px 32px !important;
}
.gallery-experience-page__grid {
  display: flex;
  gap: 40px;
  width: 100%;
}
@media screen and (max-width: 880px) {
  .gallery-experience-page__grid {
    display: block;
  }
}
.gallery-experience-page__form {
  width: 100%;
}
@media screen and (max-width: 880px) {
  .gallery-experience-page__form {
    margin-top: 20px;
  }
}
.gallery-experience-page__image {
  order: 9999;
  display: flex;
  justify-content: right;
  font-family: 'Satoshi', sans-serif;
}
@media screen and (max-width: 880px) {
  .gallery-experience-page__image {
    justify-content: center;
  }
}
@media screen and (max-width: 600px) {
  .gallery-experience-page__image__overflow {
    justify-content: start;
    overflow-x: auto;
    padding: 20px;
  }
}
.gallery-experience-page__image__wrapper {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
  padding: 24px;
  height: fit-content;
}
.gallery-experience-page__image__wrapper__section__description {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-bottom: 12px;
  width: 100%;
}
.gallery-experience-page__image__wrapper__section__description__logo {
  margin-bottom: 8px;
}
.gallery-experience-page__image__wrapper__section__description__heading {
  font-weight: 900;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: -0.02em;
}
.gallery-experience-page__image__wrapper__section__description__description {
  font-weight: 400;
  font-size: 12px;
  line-height: 170%;
  margin-top: 12px;
  letter-spacing: -0.02em;
}
.gallery-experience-page__image__wrapper__section__description__bottom {
  margin-top: 8px;
}
.gallery-experience-page__image__wrapper__section__image {
  border: 0.782348px solid #e4e4e4;
  border-radius: 6.25878px;
  padding: 15px;
  text-align: center;
  width: 100%;
}
.gallery-experience-page__image__wrapper__section__image__text {
  font-weight: 700;
  font-size: 12px;
  line-height: 170%;
  letter-spacing: -0.02em;
  margin-bottom: 15px;
}
.gallery-experience-page__image__wrapper__section__image__image img {
  height: 234px;
  width: 234px;
}
.gallery-experience-page__image__wrapper__section__bottom {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}
.gallery-experience-page__image__wrapper__section__order-1 {
  order: -1;
}
.collectible-card-v2 .separator-dark {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #3f3f3f;
}
.collectible-card-v2__image {
  position: relative;
  border-radius: 0;
  z-index: 2;
  background: #F8F8FA;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  aspect-ratio: 1;
}
.collectible-card-v2__image-overlay {
  font-family: Avenir Next Condensed, sans-serif;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  opacity: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}
.collectible-card-v2__image-overlay:hover {
  opacity: 1;
}
.collectible-card-v2__image-overlay-body {
  margin: auto;
  width: 100%;
}
.collectible-card-v2__image-overlay-body-title {
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.125rem;
}
.collectible-card-v2__image-overlay-body-button {
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  color: black;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: auto;
  margin-left: auto;
  border-radius: 30px;
  border: 1px solid white;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 200ms;
  width: 90%;
  max-width: 191px;
  padding: 6px 8px;
}
.collectible-card-v2__image-overlay-body-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
}
.collectible-card-v2__image .ant-image {
  display: block;
}
.collectible-card-v2__image img {
  border-radius: 0;
  aspect-ratio: 1;
  object-fit: cover;
  width: 100%;
}
.collectible-card-v2__image__player {
  position: absolute;
  top: 0;
  z-index: 1;
  /* matters! */
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #0000008f 100%);
  border-radius: 5px;
  width: 100%;
  height: 100%;
}
.collectible-card-v2__image__player:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.842) 0%, rgba(0, 212, 255, 0) 100%);
  z-index: -1;
  opacity: 0;
  transition: all 0.4s;
  border-radius: 5px;
}
.collectible-card-v2__image__player:hover::before {
  opacity: 1;
}
.collectible-card-v2__placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.collectible-card-v2__placeholder__content {
  text-align: center;
  color: rgba(26, 26, 26, 0.5);
}
.collectible-card-v2__placeholder__content .anticon {
  margin-bottom: 1rem;
  font-size: 4rem;
  color: rgba(26, 26, 26, 0.5);
}
.collectible-card-v2__detail {
  position: relative;
  border-radius: 0;
  padding: 16px 19px;
  z-index: 0;
  background: #ffffff;
  color: #2b2b2b;
  font-family: 'Satoshi', sans-serif;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
}
.collectible-card-v2__detail__container {
  display: flex;
  align-items: center;
  margin-bottom: 9px;
}
.collectible-card-v2__detail__title {
  margin-bottom: 3px !important;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  color: #2b2b2b !important;
}
.collectible-card-v2__detail__text {
  font-style: normal;
  font-weight: 500;
  font-size: 12.8px;
  line-height: 170%;
  color: #5c5c5c;
}
.collectible-card-v2__detail__text__bold {
  font-weight: 700;
  text-decoration: underline;
}
.collectible-card-v2__detail__url {
  font-size: 0.9rem;
  color: rgba(26, 26, 26, 0.5);
}
