.notification-header {
  padding-bottom: 3em;
  border-bottom: 1px solid #f1f1f1;
  .ant-select-selector {
    width: 250px !important;
    @media only screen and (max-width: @screen-sm) {
      width: 150px !important;
    }
  }
  &-left {
    &-readAll {
      cursor: pointer;
      margin-left: 1em;
      height: 62%;
      position: relative;
      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: black;
        transition-property: color, background-color, border-color, text-decoration-color, fill,
          stroke, opacity, box-shadow, transform, filter, backdrop-filter;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
        transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
        transform-origin: right;
        transition-duration: 200ms;
        content: '';
        transform: scaleX(0);
      }
      &:hover {
        &::before {
          transform-origin: left;
          transform: scaleX(1);
        }
      }
    }
  }
  &-right {
    @media only screen and (max-width: @screen-sm) {
      text-align: left;
    }
    text-align: right;
    .ant-btn-primary {
      background-color: rgba(248, 248, 250, 1);
      color: black;
      border: none;
      height: 40px !important;
    }
    .ant-btn-primary:hover {
      background-color: rgb(227, 227, 230);
      color: black;
      border: none;
    }
  }
}

.notification-menu {
  border-bottom: 1px solid #f1f1f1;
  padding: 1em 0 1em 0;
  .unread {
    margin-left: 0.5em;
    position: absolute;
    top: 1;
    right: 1;
    @media only screen and (max-width: @screen-xl) {
      right: 0;
    }
    width: 0.5em;
    height: 0.5em;
    margin-top: 1.3em;
    border-radius: 50px;
    background-color: #0077f8;
  }
  &-avatar {
    padding-top: 0.2rem;
  }
  &-event {
    &-name {
      margin-top: 0.1rem;
      font-weight: 500;
    }
    &-description {
      .ant-typography {
        color: rgba(26, 26, 26, 0.5);
      }
      color: rgba(26, 26, 26, 0.5);
    }
    &-time {
      @media only screen and (max-width: @screen-lg) {
        text-align: left;
      }
      text-align: right;
      padding-right: 0.5em;
      margin-top: 1.4em;
      color: rgba(26, 26, 26, 0.5);
    }
  }
}
.notification-menu:hover {
  background-color: rgba(248, 248, 250, 1);
}
