.footer {
  padding: 4.5rem 0;
  font-family: @montserrat;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 13px;
  overflow: hidden;
  position: relative;

  &-right {
    text-align: right;
    z-index: 30;
  }

  .ant-row {
    z-index: 10;
  }

  &__visit {
    display: block;
    color: @white !important;
    font-size: 15px;
  }

  &__copyright {
    margin-bottom: 0px !important;
    color: @white !important;
  }

  &__menu {
    margin-bottom: 0px !important;
    padding-left: 0px !important;

    li {
      display: inline-block !important;

      &:not(:last-child) {
        padding-right: 1.875rem;
      }

      a {
        display: block;
        color: @white !important;

        &:hover {
          text-decoration: underline !important;
        }
      }
    }
  }
}

@media screen and (min-width: 769px) {
  .footer {
    &__image {
      margin-bottom: 1.875rem;
    }
    &__copyright {
      position: absolute;
      bottom: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 3.25rem 0;
    font-size: 0.7rem !important;

    &-right,
    &-left {
      text-align: center;
    }

    &__copyright {
      margin: 1rem 0 !important;
    }
  }
}
