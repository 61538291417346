.withdrawal-modal {
  .ant-form-item-required::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 13px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
    padding-left: 5px;
  }
  &__balance {
    display: block;
    margin-bottom: 12px;
  }
  &__amount {
    padding: 5px;
  }
  &__enter-code {
    padding: 16px;
  }
  .ant-radio-group.ant-radio-group-outline {
    width: 100%;
  }
  .ant-form-vertical .ant-form-item-label > label {
    height: auto;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    padding-left: 5px;
  }
  &__payment-method {
    padding: 24px;
    margin-block: 12px;
    border: 1px solid #f1f1f1;
    width: 100%;
    &-option {
      span {
        display: flex;
        align-items: center;
      }
      display: flex;
      align-items: center;
    }
    &-destination {
      padding-block: 10px;
      border: 1px solid #e4e4e4;
    }
  }
  .payment-method-selected {
    background-color: @light-accent-lighter;
  }
}
