.fanclub-page {
  &-header {
    &-detail {
      cursor: pointer;
      font-size: 18px;
      margin-left: 6px;
      font-weight: 700;
      margin-bottom: 30px;
    }
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  &-body {
    &-load {
      &-post {
        display: flex;
        margin-top: 20px;
        width: 100%;
        &-button {
          margin: auto;
        }
      }
    }
    &-post {
      padding: 30px;
      margin-top: 30px;
      background-color: @light-accent-lighter;
      &-header {
        display: flex;
        &-profile {
          display: flex;
          &-detail {
            &-name {
              font-size: 13px;
              font-weight: 700;
            }
            &-time {
              font-size: 11px;
              color: rgba(26, 26, 26, 0.5);
            }
            padding-left: @gap-sm;
          }
        }
        &-tools {
          margin-left: auto;
          cursor: pointer;
          &-menu {
            width: 130px;
            &-edit {
              color: rgba(26, 26, 26, 0.5);
            }
            &-delete {
              color: #d6222a;
            }
          }
        }
      }
      &-body {
        margin-top: 20px;
      }
      &-separator {
        width: 100%;
        height: 1px;
        background: #e1e1e1;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      &-response {
        display: flex;
        &-likes {
          display: flex;
          &-icon {
            margin-right: 10px;
          }
        }
        &-comments {
          margin-left: auto;
        }
      }
      &-comment {
        &-child {
          display: flex;
          margin-top: 20px;
          &-wrapper {
            padding: 15px;
            width: 100%;
            background-color: #f1f1f1;
            border: 1px solid #e1e1e1;
            &-header {
              display: flex;
              &-left {
                font-size: 13px;
                font-weight: 700;
                &-time {
                  font-size: 11px;
                  color: rgba(26, 26, 26, 0.5);
                }
              }
              &-right {
                margin-left: auto;
                &-menu {
                  width: 130px;
                  &-reply {
                    color: rgba(26, 26, 26, 0.5);
                  }
                  &-delete {
                    color: #d6222a;
                  }
                }
              }
            }
            &-body {
              margin-top: 10px;
            }
          }
        }
        display: flex;
        &-padding {
          padding-left: @gap-sm;
        }
        &-wrapper {
          padding: 15px;
          width: 100%;
          background-color: #f1f1f1;
          border: 1px solid #e1e1e1;
          &-header {
            display: flex;
            &-left {
              font-size: 13px;
              font-weight: 700;
              &-time {
                font-size: 11px;
                color: rgba(26, 26, 26, 0.5);
              }
            }
            &-right {
              margin-left: auto;
              &-menu {
                width: 130px;
                &-reply {
                  color: rgba(26, 26, 26, 0.5);
                }
                &-delete {
                  color: #d6222a;
                }
              }
            }
          }
          &-body {
            margin-top: 10px;
          }
        }
      }
      &-create-comment {
        display: flex;
        padding: 30px;
        background-color: @light-accent-lighter;
        &-input {
          width: 100%;
          margin-left: 10px;
          &-editor {
            border: 1px solid #e1e1e1;
            margin-bottom: 10px;
          }
          .ant-btn {
            height: 40px;
            width: 160px;
          }
        }
        .ql-container {
          font-size: inherit;
          font-family: inherit;
          min-height: 65px;
          height: 100%;
        }
        .ql-tooltip {
          left: -12px !important;
          top: 0px !important;
        }
      }
    }
    &-create-post {
      display: flex;
      padding: 30px;
      background-color: @light-accent-lighter;
      &-input {
        width: 100%;
        margin-left: 10px;
        &-editor {
          border: 1px solid #e1e1e1;
          margin-bottom: 10px;
        }
        .ant-btn {
          height: 40px;
          width: 160px;
        }
      }
      .ql-toolbar.ql-snow {
        display: none;
      }
      .ql-editor {
        padding-top: 2px;
      }
      .ql-container {
        font-size: inherit;
        font-family: inherit;
        min-height: 65px;
        height: 100%;
      }
      .ql-tooltip {
        left: -12px !important;
        top: 0px !important;
      }
    }
    &-channel {
      @media screen and (max-width: @screen-lg) {
        padding-left: 0px;
        margin-top: 20px;
      }
      padding-left: 30px;
      &-content {
        background-color: @light-accent-lighter;
        padding-bottom: 25px;
        &-header {
          padding-left: 30px;
          padding-top: 30px;
          padding-bottom: 15px;
          font-size: 20px;
          font-weight: 700;
        }
        &-list {
          &-item {
            &-active {
              cursor: pointer;
              padding: 10px 30px;
              background-color: #f1f1f1;
              &-left {
                &-name {
                  font-size: 13px;
                  font-weight: 700;
                }
                &-activity {
                  font-size: 10px;
                }
              }
              &-right {
                text-align: right;
              }
            }
            cursor: pointer;
            padding: 10px 30px;
            &-left {
              &-name {
                font-size: 13px;
                font-weight: 700;
              }
              &-activity {
                font-size: 10px;
              }
            }
            &-right {
              text-align: right;
            }
          }
          &-item:hover {
            background-color: #f1f1f1;
          }
        }
      }
      &-button {
        width: 100%;
      }
      .ant-btn {
        margin-top: 30px;
        height: 40px;
      }
    }
  }
}

.fanclub-channel-created-modal {
  .ant-modal-close {
    &-x {
      font-size: 1rem;
      .anticon {
        color: @light-text-50;
        &:hover {
          color: @light-text;
        }
      }
    }
  }
  .fanclub-channel-created-modal-container {
    padding: 32px;
    &-header {
      text-align: center;
      &-title {
        font-weight: 700;
        font-size: 20px;
        margin-top: 25px;
        margin-bottom: 20px;
      }
      &-text {
        color: rgba(26, 26, 26, 0.5);
        text-align: center;
      }
    }
  }
}

.fanclub-channel-delete-modal {
  .ant-modal-close {
    &-x {
      font-size: 1rem;
      .anticon {
        color: @light-text-50;
        &:hover {
          color: @light-text;
        }
      }
    }
  }
  .fanclub-channel-delete-modal-container {
    padding: 32px;
    &-header {
      text-align: center;
      &-title {
        font-weight: 700;
        font-size: 20px;
        margin-top: 25px;
        margin-bottom: 20px;
      }
      &-text {
        color: rgba(26, 26, 26, 0.5);
        text-align: center;
      }
    }
    &-footer {
      width: 100%;
      text-align: center;
      &-delete {
        margin-right: 20px !important;
      }
      .ant-btn {
        height: 40px;
        width: 100px;
        border-radius: 0px !important;
      }
    }
  }
}

.fanclub-channel-update-modal {
  .ant-modal-close {
    &-x {
      font-size: 1rem;
      .anticon {
        color: @light-text-50;
        &:hover {
          color: @light-text;
        }
      }
    }
  }
  &-private {
    margin-bottom: 24px;
    width: 100%;
    display: flex;
    &-left {
      &-description {
        color: rgba(26, 26, 26, 0.5);
        max-width: 345px;
        margin-top: 5px;
      }
    }
    &-right {
      margin-top: 5px;
      margin-left: auto;
    }
  }
  .admin-form .ant-btn {
    padding: 0.5rem 2rem;
  }
}

.fanclub-upload-image {
  .ant-upload.ant-upload-drag {
    height: 200px;
    width: 200px;
  }
  .fanclub-dragger {
    display: inline-block;
  }
  .fanclub-image-container {
    position: relative;
    display: inline-block;
  }
  .fanclub-image-remove {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0.5rem !important;
  }
}
