.dash {
  &-sider {
    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 100vh;
    }
    &__top {
      &__logo {
        padding: 0 2rem;
        .logo {
          display: flex;
          padding: 2rem 0 0.75rem;
          color: #fff;
          @media only screen and (max-width: @screen-xl) {
            display: none;
          }
        }
      }
      &__notif {
        display: inline-block;
        margin: auto;
        text-align: right;
        &__value {
          .ant-badge-count {
            box-shadow: none !important;
          }
        }
      }
    }
    &.ant-layout-sider {
      position: fixed;
      min-height: 100vh;
      height: 100%;
      z-index: 99;
      left: 0;
      border-right: none !important;
      overflow: auto;
      overflow-x: hidden;
      .ant-layout-sider-zero-width-trigger {
        display: none !important;
      }
      .ant-layout-sider-trigger {
        display: none;
      }
      &-has-trigger {
        padding-bottom: 0;
      }
    }
    &__group {
      &__name {
        padding: 1.25rem 2rem 0.625rem;
        text-transform: uppercase;
        font-size: 0.625rem;
        line-height: 1rem;
        letter-spacing: 0.05em;
        color: @white;
        @media only screen and (max-width: @screen-xl) {
          padding: 1rem 2rem 0rem;
        }
      }
      .dash-sider__menu {
        margin-top: 0 !important;
      }
      .organizations-title {
        font-size: 0.8rem;
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        position: relative;
        padding-left: 0;
        background-color: inherit;
        color: @white;
        border: none;
        border-radius: 5px;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
      .ant-select-arrow {
        color: @white;
      }
    }
    &__menu {
      border-right: none !important;
      &__switch {
        margin-left: 13px;
        font-size: 0.8125rem !important;
      }
      &__notification {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 40px);
      }
      & > li {
        padding: 0 2rem !important;
        @media only screen and (max-width: @screen-xl) {
          padding: 0 2rem !important;
        }
      }
      li {
        margin: 0 !important;
        height: 36px !important;
        line-height: 36px !important;
        @media only screen and (max-width: @screen-xl) {
          height: 36px !important;
          line-height: 36px !important;
        }
        span:nth-child(2) {
          margin-left: 0px !important;
        }
        svg {
          font-size: 14px;
        }
      }
      .ant-menu-item {
        &:after {
          border: none !important;
        }
        &-selected,
        &:hover {
          background: rgba(214, 34, 42, 0.05) !important;
          a {
            color: @white;
          }
          &:after {
            right: unset;
            left: 0;
            border: none !important;
            border-radius: 0 8px 8px 0;
            width: 5px !important;
            background: @fans-red !important;
          }
        }
      }
      a {
        font-size: 0.8125rem;
        .anticon {
          margin-right: 10px;
          vertical-align: middle;
          font-size: 1.25rem !important;
        }
      }
      /* User menu */
      .menu-user {
        padding-left: 30px !important;
        &:first-child {
          height: 50px !important;
          margin: 20px 0 !important;
        }
        @media only screen and (max-width: @screen-xl) {
          padding-left: 26px !important;
        }
        a {
          display: flex;
          align-items: center;
          background-color: @dark-secondary;
          padding: 10px !important;
        }
        .ant-avatar {
          margin-right: 10px;
          width: 30px !important;
          height: 30px !important;
          line-height: 30px !important;
          background: @white;
        }
      }
      /* Logout menu */
      &--logout {
        margin-bottom: 1.5rem !important;
      }
    }
    &__separator {
      padding: 1.5rem 3rem 1.25rem;
      @media only screen and (max-width: @screen-xl) {
        padding: 1rem 2rem 0rem;
      }
      span {
        display: block;
        height: 1px;
        background: #393e51;
      }
    }
  }
  &-content {
    margin-left: 250px;
    min-height: 100vh;
    padding: 0 1.875rem 2.5rem;
    transition: all 0.2s ease;
    @media only screen and (max-width: @screen-xl) {
      margin-left: 0;
      padding: 0 2rem 2rem;
    }
    @media only screen and (max-width: @screen-md) {
      padding: 0 1rem 1rem;
    }
    &__overlay {
      display: none;
      position: fixed;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      left: 0;
      top: 0;
      z-index: 1;
      &.siderOpened {
        @media only screen and (max-width: @screen-xl) {
          display: block;
        }
      }
    }
    &__header {
      display: flex;
      align-items: center;
      border-bottom: 1px solid @light-accent;
      margin: 0 -1.875rem @gap-lg;
      height: 82px;
      padding: 0px 1.875rem;
      @media only screen and (max-width: @screen-xl) {
        height: auto;
        padding: @gap-md 2rem;
      }
      .toggle-sider,
      &__back {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border-radius: @border-radius-base;
        margin-right: 1rem;
        transition: all 0.2s ease;
        z-index: 2;
        background: @light-accent-lighter;
        cursor: pointer;
        .anticon {
          font-size: 1.5rem;
          color: @dark;
          display: block;
        }
      }
      .toggle-sider {
        svg {
          font-size: 15px;
        }
      }
      &__back {
        .anticon {
          font-size: @gap-sm;
        }
      }
      h1.ant-typography {
        margin-bottom: 0;
        font-size: 1.125rem !important;
        font-weight: 700;
      }
    }
  }
  &-main {
    &__stats {
      &__box {
        margin-bottom: 15px;
        border-radius: @border-radius-base;
        background: @dark;
        padding: @gap-md;
        .ant-statistic {
          &-title {
            color: @white;
          }
          &-content {
            color: @white;
          }
        }
      }
    }
    &__graph {
      border-radius: @border-radius-base;
      background: @dark;
      padding: @gap-lg;
    }
    &__title {
      font-size: 1.5rem !important;
      padding: 1rem 0;
    }
  }
  /* Sider Closed */
  &.siderClosed {
    .dash-sider {
      @media only screen and (max-width: @screen-xl) {
        min-width: unset !important;
        width: 0 !important;
      }
      &__top {
        &__logo {
          display: flex;
          justify-content: center;
          padding: 0 2rem;
        }
      }
      &__group {
        &__name {
          display: none;
        }
      }
      .ant-menu {
        &-item {
          a {
            color: @white;
          }
          &.menu-user {
            padding: 0 !important;
            display: flex;
            align-items: center;
            justify-content: center;
            .menu-user__name {
              display: none;
            }
          }
        }
      }
      .ant-tooltip {
        a {
          color: @white !important;
        }
      }
    }
    .dash-content {
      margin-left: 90px;
      @media only screen and (max-width: @screen-xl) {
        margin-left: 0;
      }
    }
  }
}
.ant-select-dropdown .rc-virtual-list-holder .account-option:hover {
  background-color: #f8f8fa !important;
  .ant-select-item-option-content {
    color: black !important;
  }
}
