.inbox {
  &-toolbar {
    .toolbar-search .ant-select {
      @media screen and (max-width: 767px) {
        min-width: 80px;
      }
      min-width: 164px;
    }
  }
  &-toolbar-search {
    min-width: 255px;
    @media screen and (max-width: 767px) {
      min-width: 40%;
    }
    .toolbar-search {
      max-width: unset;
    }
  }
  &-id {
    display: flex;
    align-items: center;
    &-unread {
      margin-left: 8px;
      width: 8px;
      height: 8px;
      border-radius: 100px;
      background: #0077f8;
    }
  }
  &-submitted-date {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  &-page-message {
    max-width: 250px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-page-search {
    margin-top: 40px;
    margin-bottom: 20px;
    &-readAll {
      .ant-btn {
        height: 40px;
      }
      .ant-btn > span {
        display: unset;
        padding: 12px 16px;
      }
    }
  }
  &-action {
    color: @dark !important;
    opacity: 0.5;
    cursor: pointer;
    @media screen and (min-width: @screen-sm) {
      font-size: 10px;
    }
  }
  &-toolbar {
    &-right {
      display: flex;
      align-items: center;
      justify-content: right;
      @media screen and (max-width: 767px) {
        justify-content: left;
        margin-top: 8px;
      }
      &-wrapper {
        display: flex;
        align-items: center;
        align-items: right;
        gap: 10px;
        @media screen and (max-width: 767px) {
          display: flex;
        }
      }
    }
    .ant-input {
      border-radius: 0px;
      border-top: 1px solid #e1e1e1;
      border-left: 1px solid #e1e1e1;
      border-bottom: 1px solid #e1e1e1;
      border-right: none;
    }
    .ant-input:focus {
      border-radius: 0px;
      border-top: 1px solid #e1e1e1;
      border-left: 1px solid #e1e1e1;
      border-bottom: 1px solid #e1e1e1;
    }
    .ant-input:hover {
      border-radius: 0px;
      border-top: 1px solid #e1e1e1;
      border-left: 1px solid #e1e1e1;
      border-bottom: 1px solid #e1e1e1;
    }
    .ant-input-search
      > .ant-input-group
      > .ant-input-group-addon:last-child
      .ant-input-search-button {
      border-radius: 0px;
      border-top: 1px solid #e1e1e1;
      border-right: 1px solid #e1e1e1;
      border-bottom: 1px solid #e1e1e1;
    }
  }
}

.inbox-modal {
  .ant-modal-body {
    padding-top: 12px;
  }
  .ant-modal-close {
    &-x {
      font-size: 1rem;
      .anticon {
        color: @light-text-50;
        &:hover {
          color: @light-text;
        }
      }
    }
  }
  .inbox-modal-container {
    &-header {
      text-align: center;
      &-title {
        font-weight: 700;
        font-size: 20px;
        margin-top: 25px;
        margin-bottom: 20px;
      }
      &-text {
        color: rgba(26, 26, 26, 0.5);
        text-align: center;
      }
    }
    &-detail {
      &-table {
        width: 100%;
        font-size: 15px;
        &-row {
          &-left {
            width: 30%;
            font-weight: 700;
          }
          border-bottom: 1px solid #f1f1f1;
          line-height: 50px;
          &-avatar {
            display: flex;
            .avatar-name {
              margin-right: @gap-sm;
            }
          }
          &-last {
            &-left {
              width: 30%;
              font-weight: 700;
            }
            line-height: 50px;
            &-avatar {
              display: flex;
              .avatar-name {
                margin-right: @gap-sm;
              }
            }
          }
        }
      }
    }
    &-footer {
      width: 100%;
      margin-top: 20px;
      text-align: center;
      &-approve {
        margin-right: 20px !important;
      }
      .ant-btn {
        border-radius: 0px !important;
      }
    }
  }
}

.inbox-delete-modal {
  .ant-modal-close {
    &-x {
      font-size: 1rem;

      .anticon {
        color: @light-text-50;

        &:hover {
          color: @light-text;
        }
      }
    }
  }

  .inbox-delete-modal-container {
    padding: 32px;

    &-header {
      text-align: center;

      &-title {
        font-weight: 700;
        font-size: 20px;
        margin-top: 25px;
        margin-bottom: 20px;
      }

      &-text {
        color: rgba(26, 26, 26, 0.5);
        text-align: center;
      }
    }

    &-footer {
      width: 100%;
      text-align: center;

      &-delete {
        margin-right: 20px !important;
      }

      .ant-btn {
        height: 40px;
        width: 100px;
        border-radius: 0px !important;
      }
    }
  }
}
