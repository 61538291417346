.balance {
  &__amount {
    margin-bottom: @gap-lg;
    background-color: @light-accent-lighter;
    padding: 36px;
    &-desc {
      padding-bottom: 24px;
    }
    &-current {
      border: 1px dashed rgba(26, 26, 26, 0.5);

      padding: 40px;
      text-align: center;
    }
    &-text {
      text-transform: uppercase;
      display: block;
    }
    &-value {
      font-size: 32px;
      font-weight: bold;
      display: block;
    }
  }
}

.withdrawal-modal {
  .ant-modal-header {
    padding: 24px;
  }
  .ant-modal-close {
    &-x {
      font-size: 1rem;
      .anticon {
        color: @light-text-50;
        &:hover {
          color: @light-text;
        }
      }
    }
  }

  .ant-modal-title {
    font-size: 1.25rem;
  }
}
