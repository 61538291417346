.withdrawal {
  .avatar-name {
    padding-left: @gap-sm;
  }
  .withdrawal-action {
    display: flex;
    .withdrawal-action-approve {
      color: #27ae60;
      cursor: pointer;
      margin-right: @gap-lg;
    }
    .withdrawal-action-reject {
      color: #d6222a;
      cursor: pointer;
    }
  }
}

.withdrawal-page-search {
  margin-top: 40px;
  margin-bottom: 20px;
}
.withdrawal-toolbar {
  .ant-input {
    border-radius: 0px;
    border-top: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    border-right: none;
  }
  .ant-input:focus {
    border-radius: 0px;
    border-top: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
  }
  .ant-input:hover {
    border-radius: 0px;
    border-top: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
  }
  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border-radius: 0px;
    border-top: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
  }
}

.withdrawl-modal {
  .ant-modal-close {
    &-x {
      font-size: 1rem;
      .anticon {
        color: @light-text-50;
        &:hover {
          color: @light-text;
        }
      }
    }
  }
  .withdrawl-modal-container {
    padding: 32px;
    &-header {
      text-align: center;
      &-title {
        font-weight: 700;
        font-size: 20px;
        margin-top: 25px;
        margin-bottom: 20px;
      }
      &-text {
        color: rgba(26, 26, 26, 0.5);
        text-align: center;
      }
    }
    &-detail {
      &-table {
        width: 100%;
        &-row {
          &-left {
            width: 30%;
            font-weight: 700;
          }
          border-bottom: 1px solid #f1f1f1;
          line-height: 60px;
          &-avatar {
            display: flex;
            .avatar-name {
              margin-right: @gap-sm;
            }
          }
        }
      }
    }
    &-footer {
      width: 100%;
      margin-top: 20px;
      text-align: center;
      &-approve {
        margin-right: 20px !important;
      }
      .ant-btn {
        border-radius: 0px !important;
      }
    }
  }
}
