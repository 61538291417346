.waitlist {
  &-page-search {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  &-action {
    color: @dark !important;
    opacity: 0.5;
    cursor: pointer;
    @media screen and (min-width: @screen-sm) {
      font-size: 10px;
    }
  }
  &-toolbar {
    &-right {
      text-align: right;
      @media screen and (max-width: @screen-sm) {
        text-align: left;
        margin-top: 10px;
      }
    }
    .ant-input {
      border-radius: 0px;
      border-top: 1px solid #e1e1e1;
      border-left: 1px solid #e1e1e1;
      border-bottom: 1px solid #e1e1e1;
      border-right: none;
    }
    .ant-input:focus {
      border-radius: 0px;
      border-top: 1px solid #e1e1e1;
      border-left: 1px solid #e1e1e1;
      border-bottom: 1px solid #e1e1e1;
    }
    .ant-input:hover {
      border-radius: 0px;
      border-top: 1px solid #e1e1e1;
      border-left: 1px solid #e1e1e1;
      border-bottom: 1px solid #e1e1e1;
    }
    .ant-input-search
      > .ant-input-group
      > .ant-input-group-addon:last-child
      .ant-input-search-button {
      border-radius: 0px;
      border-top: 1px solid #e1e1e1;
      border-right: 1px solid #e1e1e1;
      border-bottom: 1px solid #e1e1e1;
    }
  }
}
