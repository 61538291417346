.edition-badge {
  position: relative;
  display: inline-block;
  color: @dark;
  text-align: center;
  svg {
    display: inline-block;
  }
  &__edition {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 16px;
  }
  &--small {
    svg {
      width: 24px;
      height: 24px;
    }
    .edition-badge__edition {
      display: none;
    }
  }

  &--base {
    svg {
      height: 40px;
      width: 40px;
    }
    .edition-badge__edition {
      display: none;
    }
  }
}
