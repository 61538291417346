.profile-avatar {
  display: flex;
  align-items: center;
  .ant-avatar {
    margin-right: @gap-sm;
  }
}

.creator-tabs {
  .ant-tabs-nav-list {
    width: 99.9%;
  }
  .ant-tabs-tab {
    width: 50%;
  }
  .ant-tabs-tab-btn {
    width: 100%;
    text-align: center;
  }
  .ant-form-item-control-input {
    border: 1px solid #e1e1e1;
  }
}

.add-profile-images {
  @media only screen and (max-width: @screen-lg) {
    text-align: center;
  }
  .ant-form-item {
    display: block;
    &-input {
      flex-wrap: wrap;
    }
  }
  .ant-form-item-control-input {
    border: 1px dashed #e1e1e1;
  }
}

.image-preview-field {
  padding-top: 22px;
  position: relative !important;
  width: 100%;
  height: 160px;
  text-align: center;
  &-description {
    margin: auto;
    &-text {
      padding-top: 8px;
      font-weight: 700;
      color: #1a1a1a;
    }
    &-hint {
      max-width: 310px;
      text-align: center;
      padding-top: 8px;
      font-size: 12px;
      color: rgba(26, 26, 26, 0.5);
      margin: auto;
    }
  }
  &-cover-update {
    padding-top: 48px;
  }
  .ant-btn {
    padding: 2px !important;
  }
  &-remove {
    position: absolute !important;
    margin: 10px;
    right: 0;
    top: 0;
  }
  &__loading {
    position: absolute !important;
    left: 0;
    bottom: 0;
  }
}

.image-preview-avatar-field {
  padding-top: 134px;
  background-color: #f8f8fa;
  position: relative !important;
  width: 100%;
  height: 380px;
  text-align: center;
  &-description {
    margin: auto;
    &-text {
      padding-top: 8px;
      font-weight: 700;
      color: #1a1a1a;
    }
    &-hint {
      max-width: 310px;
      text-align: center;
      padding-top: 8px;
      font-size: 12px;
      color: rgba(26, 26, 26, 0.5);
      margin: auto;
    }
  }
  &-avatar-update {
    padding-top: 30px;
  }
  .ant-btn {
    padding: 2px !important;
  }
  &-remove {
    position: absolute !important;
    margin: 10px;
    right: 0;
    top: 0;
  }
  &__loading {
    position: absolute !important;
    left: 0;
    bottom: 0;
  }
}

.creator-page-right {
  padding-left: 30px;
  @media screen and (max-width: 990px) {
    padding-left: 0px;
  }
  &-button {
    width: 100%;
    margin-bottom: 20px;
    padding: 0px !important;
    height: 40px !important;
    @media screen and (max-width: 990px) {
      display: none;
    }
    &-bottom {
      padding: 0px !important;
      height: 40px !important;
      @media screen and (min-width: 990px) {
        display: none;
      }
      width: 100%;
    }
  }
  &-wrapper {
    margin-bottom: 20px;
    background-color: @light-accent-lighter;
    padding: 16px;
    &-title {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 16px;
    }
    .ant-select {
      border: 1px solid #e1e1e1;
    }
    &-editors {
      padding-bottom: 16px;
      &-avatar-name {
        padding-left: @gap-sm;
        color: #2b2b2b;
      }
      &-button {
        width: 100%;
        height: 40px !important;
        padding: 0px !important;
      }
    }
    &-underline {
      height: 1px;
      width: 100%;
      background-color: #f1f1f1;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    &-field {
      width: 100%;
      display: flex;
      .ant-tag {
        margin: 0;
      }
      &-left {
        margin-right: auto;
        color: rgba(26, 26, 26, 0.5);
      }
      &-right {
        &-fee {
          color: rgba(26, 26, 26, 0.5);
          display: flex;
          .ant-form-item {
            margin-bottom: 0px;
          }
          .ant-input-number-input {
            text-align: right !important;
            color: rgba(26, 26, 26, 0.5);
            padding-right: 0px !important;
          }
          .ant-form-item-control-input-content {
            text-align: right;
          }
          &-icon {
            margin-top: 7px;
            display: flex;
            &-percent {
              margin-right: 8px;
            }
          }
        }
        &-icon {
          color: #d6222a;
          margin-top: 10px;
          cursor: pointer;
        }
        &-owner {
          color: rgba(26, 26, 26, 0.5);
          margin-top: 8px;
        }
      }
    }
    &-shipping {
      color: rgba(26, 26, 26, 0.5);
      &-owner {
        font-weight: 600;
      }
    }
  }
}
