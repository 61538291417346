.admin {
  &-toolbar {
    justify-content: space-between !important;
    .ant-select-selector {
      background-color: @light-accent-lighter !important;
    }
    .toolbar__right > div {
      width: 163px;
    }
    .ant-select-selector {
      height: 45px !important;
      padding: 1rem 1.25rem;
      .ant-select-selection-search-input,
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        height: 45px !important;
        padding: 0.3rem 0;
      }
    }
  }
  &-collectible-modal {
    .ant-modal-body {
      padding: 40px 32px 32px;
    }
    &__icon {
      margin-bottom: 2rem;
      font-size: 70px;
    }
    &__desc {
      color: #1a1a1a80;
      &-revision {
        margin: -12px -8px -8px;
        text-align: left;
      }
    }
    &__button {
      font-size: 13px;
      padding: 13px 29.5px !important;
      line-height: 19px;
      height: auto;
      &-default {
        background: #f1f1f1;
        border-color: #f1f1f1;
        margin-left: 20px;
      }
      &-error {
        background: #d6222a;
        border-color: #d6222a;
        color: white;
      }
      &-success {
        background: #27ae60;
        border-color: #27ae60;
        color: white;
        &:hover {
          border-color: #27ae60;
          color: #27ae60;
        }
      }
    }
  }
  &-collectible.admin-form {
    .ant-form-item {
      font-size: 13px !important;
    }
    .ant-form-item-label > label {
      color: #1a1a1a80 !important;
    }
    .collectible-media {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px;
      background-color: @light-accent-lighter;
      img {
        flex-shrink: 0;
        flex-grow: 0;
        height: 86px;
        width: 86px;
        object-fit: cover;
      }
      .ant-image-mask:hover {
        opacity: 0;
      }
      .text {
        display: block;
        &-title {
          font-weight: 500;
        }
        &-desc {
          color: #1a1a1a80;
        }
      }
    }
    .title {
      margin-bottom: 1rem;
      font-size: 24px;
      font-weight: 700;
      line-height: 29.05px;
    }
    .subtitle {
      color: rgba(26, 26, 26, 0.5) !important;
      margin-bottom: 1.25rem;
      font-size: 12px;
      line-height: 13.03px;
    }
    .group-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 20px;
    }
  }
  &-collectible {
    &-timeline {
      padding: 1.25rem;
      margin-bottom: 1.25rem;
      background-color: @light-accent-lighter;
      &__date {
        color: #1a1a1a80 !important;
      }
      .ant-timeline-item {
        padding-bottom: 0;
      }
    }
    @media screen and (max-width: @screen-sm) {
      .btn-action {
        padding: 1rem 0 !important;
        font-size: inherit !important;
      }
    }
    .admin-faq {
      margin-top: 20px;
      background-color: @light-accent-lighter;
      padding: 16px;
      &-question {
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        padding-bottom: 10px;
      }
    }
    .btn-action {
      font-size: 1rem;
      line-height: 19.36px;
      font-weight: 400;
    }
    .btn-approve {
      background-color: @green;
      color: white;
      border-color: @green;
    }
  }
}
