.toolbar-invite {
  width: 480px;
  .ant-input {
    padding: 1rem 1.25rem;
    height: 40px;
  }
  .quick-invite-form {
    .ant-form-item {
      margin: 0;
      width: 70%;
      .ant-input {
        border-radius: @border-radius-base 0 0 @border-radius-base;
      }
    }
    .ant-btn {
      height: 40px !important;
      flex: 1;
      border-radius: 0 @border-radius-base @border-radius-base 0;
    }
  }
}
.hide {
  display: none;
}
