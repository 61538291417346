.container {
  margin: auto;
  width: 100%;
  max-width: @screen-xl;
}

@media screen and (max-width: @screen-xl) {
  .container {
    padding: 0px 2rem;
  }
}
