.auth-header {
  background-color: #fbfbfb;
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 1;
  align-items: center;
  justify-content: center;
  height: 110px;
  border-bottom: 1px solid #e9e9e9;
  &__body {
    max-width: 1300px;
    margin: 0px 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    &__left {
      align-items: center;
      display: flex;
      img {
        width: 310px;
        height: 36px;
        @media screen and (max-width: 500px) {
          width: 200px;
          height: 24px;
        }
      }
    }
    &__right {
      display: flex;
      gap: 16px;
      align-items: center;
      &__text {
        font-size: 18px;
        line-height: 170%;
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
      .ant-btn {
        border-radius: 4px;
        height: 47px;
        width: 122px;
      }
    }
  }
}
