.admin-form {
  &-collectibles {
    &-button {
      margin-top: 20px;
    }
  }
  .preview-sales {
    margin-top: @gap-lg;
    padding: @gap-md;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
    background: @light-accent-lighter;
    h3 {
      margin-bottom: @gap-md;
      font-size: @font-body-lg;
      font-weight: 400;
    }
    &__list {
      color: @dark-50;
      &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid @light-accent;
        padding: @gap-sm 0;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
  .ant-upload-list-item-info {
    .ant-btn-icon-only > .anticon.anticon-delete {
      display: block;
      transform: translateY(-40%);
    }
  }
  .collectible-form-title {
    margin-bottom: 17px !important;
    line-height: 29.05px;
    .ant-form-item-control-input {
      min-height: auto !important;
    }
    .ant-input {
      padding: 0 !important;
      font-size: 1.5rem;
      font-weight: 700;
    }
  }
  .collectible-form-slug {
    line-height: 13.03px;
    .ant-form-item-control-input {
      min-height: auto !important;
    }
    .ant-input,
    span.form-slug-label {
      padding: 0 !important;
      font-size: 0.75rem;
      color: #1a1a1a80;
    }
  }
}

.collectibles {
  &-detail {
    &__title {
      margin-bottom: 0px !important;
      padding: 1rem !important;
      font-size: 1.5rem !important;
    }
    &__stats {
      &__box {
        margin-bottom: 1rem;
        border: 1px solid @light-accent;
        border-radius: @border-radius-base;
        background: @light-accent-lighter;
        padding: @gap-md;
      }
    }
  }
}

@media screen and (max-width: 1600px) and (min-width: 1301px) {
  .collectible-form {
    &-left {
      max-width: 66.66% !important;
      flex-basis: 66.66%;
    }
    &-right {
      max-width: 33.33% !important;
      flex-basis: 33.33%;
    }
  }
}

@media screen and (max-width: 1300px) and (min-width: 1200px) {
  .collectible-form {
    &-left {
      max-width: 60% !important;
      flex-basis: 60%;
    }
    &-right {
      max-width: 40% !important;
      flex-basis: 40%;
    }
  }
}

.select-creatable {
  .css-1rhbuit-multiValue {
    background-color: black !important;
    padding: 0.2em;
    border-radius: 5px !important;
    margin-right: 1em !important;
    color: white !important;
  }

  .css-12jo7m5 {
    color: white !important;
  }

  .css-1s2u09g-control {
    height: 4em !important;
    background-color: #f8f8fa !important;
  }

  .css-tlfecz-indicatorContainer {
    color: hsl(0, 0%, 80%);
    display: none;
  }

  .css-1pahdxg-control {
    height: 4em !important;
    background-color: #f8f8fa !important;
    border-color: black !important;
    box-shadow: none !important;
  }

  &-error {
    .css-1pahdxg-control,
    .css-1s2u09g-control {
      border-color: #d6222a !important;
      box-shadow: none !important;
      &:focus {
        border-color: #e3494b !important;
        box-shadow: 0 0 0 2px rgb(214 34 42 / 20%) !important;
      }
    }
  }

  .css-lgtu0rj-indicatorContainer {
    display: none !important;
  }

  .css-1hb7zxy-IndicatorsContainer {
    display: none !important;
  }

  .css-xb97g8.fans__multi-value__remove:hover {
    background-color: inherit !important;
    color: inherit !important;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(49, 44, 56, 0.2);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgb(156, 155, 155);
  }
}

.form-hidden {
  display: none;
}
