.auth-footer {
  background: #2b2b2b;
  border-top: 1px solid #d9d9d9;
  color: white;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  &__wrapper {
    width: 100%;
    max-width: 1300px;
    margin: 0px 20px;
    display: flex;
    justify-content: space-between;
  }
  &__left {
    img {
      width: 310px;
      height: 36px;
      @media screen and (max-width: 760px) {
        width: 200px;
        height: 24px;
      }
    }
    &__text {
      @media screen and (max-width: 760px) {
        font-size: 12px;
        line-height: 100%;
      }
      font-size: 16px;
      line-height: 170%;
      margin-top: 6px;
    }
  }
  &__right {
    @media screen and (max-width: 760px) {
      font-size: 12px;
      line-height: 100%;
    }
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 170%;
    &__visit {
      font-weight: 700;
      display: flex;
      color: white;
      align-items: center;
      gap: 10px;
      @media screen and (max-width: 500px) {
        gap: 5px;
      }
      justify-content: right;
      svg {
        @media screen and (max-width: 500px) {
          height: 16px;
        }
      }
    }
    &__link {
      margin-top: 6px;
      display: flex;
      align-items: center;
      @media screen and (max-width: 760px) {
        display: none;
      }
      gap: 24px;
      &__item {
        color: white;
      }
    }
  }
}
