.image-collage-container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.image-collage-container img,
.image-collage-container div {
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: center;
  cursor: pointer;

  @media screen and (min-width: 425px) {
    height: 500px;
  }
}

.image-collage-container-1 img,
.image-collage-container-2 img,
.image-collage-container-3 img,
.image-collage-container-1 div,
.image-collage-container-2 div,
.image-collage-container-3 div {
  width: 100%;
  height: 125px;
  object-fit: cover;
  object-position: center;

  @media screen and (min-width: 425px) {
    height: 250px;
  }
}

.image-collage-overlay {
  height: 100px;
  width: calc(100% / 3);
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);

  @media screen and (min-width: 425px) {
    height: 200px;
  }
}

.image-collage-container img:not(:first-child),
.image-collage-container div:not(:first-child) {
  height: 100px;

  @media screen and (min-width: 425px) {
    height: 200px;
  }
}

.image-collage-container-1 img:not(:first-child),
.image-collage-container-1 div:not(:first-child) {
  height: 125px;

  @media screen and (min-width: 425px) {
    height: 250px;
  }
}

.image-collage-container-2 img:not(:first-child),
.image-collage-container-2 div:not(:first-child) {
  width: calc(100% / 2);
}

.image-collage-container-3 img:not(:first-child),
.image-collage-container-3 div:not(:first-child) {
  width: calc(100% / 3);
}

.image-collage-container video {
  object-fit: cover !important;
}
.lightbox-container {
  // fixed w-full z-50 top-0 left-0 hidden overflow-hidden text-center;
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  display: none;
  overflow: hidden;
  text-align: center;
  transition: 0.5s;
  height: 100vh;
  // background-color: @dark;
  background-color: rgba(26, 26, 26, 0.6);
  &-show {
    // @apply flex items-center justify-center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__wrapper {
    // @apply inline-flex items-center justify-center relative;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 100vh;
    width: 100%;

    &__content {
      // @apply contents;
    }
  }

  &__image {
    // @apply inline-block;
    display: inline-block;
    max-height: 90%;
    max-width: 95%;
    .player {
      max-width: 95%;
      left: unset;
    }
  }

  .player {
    position: relative;
    width: 100%;
    max-width: 100%;

    .ant-btn.ant-btn-text.player__controls__button {
      width: auto;
    }
  }
}
