.order-history {
  .avatar-name {
    padding-left: @gap-sm;
  }
  .order-history-payment {
    font-size: 10px;
    color: rgba(26, 26, 26, 0.5);
  }
  .order-history-id {
    text-decoration: underline;
  }
  .order-history-description {
    max-width: 250px;
  }
}

.order-toolbar {
  margin-top: 30px;
  margin-bottom: 10px;
  .ant-input {
    border-radius: 0px;
    border-top: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    border-right: none;
  }
  .ant-input:focus {
    border-radius: 0px;
    border-top: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
  }
  .ant-input:hover {
    border-radius: 0px;
    border-top: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
  }
  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border-radius: 0px;
    border-top: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
  }
}

.detail-order {
  &-image-patrons {
    &__wrapper {
      background-color: red;
      &__zoom {
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 4px;
        cursor: pointer;
        padding-top: 11px;
        padding-left: 11px;
        padding-right: 11px;
        padding-bottom: 8px;
        background: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(10px);
        margin: 20px;
      }
    }
    &__zoom {
      position: fixed;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(25px);
      width: 100%;
      height: 100%;
      z-index: 99999999999;
      &__image {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        &__close {
          position: absolute;
          right: 0;
          top: 0;
          cursor: pointer;
          margin: 42.5px;
        }
        width: 100%;
        height: 100%;
        &__media {
          display: flex;
          align-items: center;
          align-content: center;
          width: 90vw;
          height: 90vh;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
  &-wrapper {
    margin-bottom: 20px;
    background-color: @light-accent-lighter;
    padding: 16px;
    &-title {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 16px;
    }
    &-underline {
      height: 1px;
      width: 100%;
      background-color: #f1f1f1;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    &-field {
      width: 100%;
      display: flex;
      .ant-tag {
        margin: 0;
      }
      &-left {
        margin-right: auto;
        color: rgba(26, 26, 26, 0.5);
      }
    }
    &-shipping {
      color: rgba(26, 26, 26, 0.5);
      &-owner {
        font-weight: 600;
      }
    }
  }
  &-space {
    height: 20px;
  }
}
