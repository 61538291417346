.beta-features {
  display: flex;
  padding-top: 2em;
  &-content {
    width: 100%;
    padding: 1em;
    h1 {
      text-align: center;
      margin-bottom: 1em;
    }
    margin: auto;
  }
  .vlag-features__item {
    box-shadow: none;
  }
  .vlag-switch__item-active .vlag-switch__item__box {
    background-color: black;
  }
}
