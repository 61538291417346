.post {
  padding: 30px;
  background-color: @light-accent-lighter;

  &-featured {
    display: flex;
    margin-bottom: 15px;

    &-text {
      font-weight: 700;
      font-size: 13px;
      margin-left: 6px;
    }
  }

  &-header {
    &-profile {
      display: flex;
      cursor: pointer;

      &-detail {
        width: 100%;
        img {
          width: 100%;
        }
        &-name {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 14px;
          width: 100%;
          display: flex;
          align-items: center;
          &-left {
            margin-right: auto;
          }
          &-right {
            display: flex;
            align-items: center;
            font-size: 13px;
            font-weight: 400;
            &-count {
              margin-left: 4px;
            }
          }
        }

        &-time {
          font-size: 11px;
          color: rgba(26, 26, 26, 0.5);
        }
      }
    }

    &-tools {
      &-ellipsis {
        font-size: 16px;
        cursor: pointer;
      }

      margin-left: auto;

      &-menu {
        width: 130px;

        &-edit {
          color: rgba(26, 26, 26, 0.5);
        }

        &-featured {
          color: rgba(26, 26, 26, 0.5);
        }

        &-delete {
          color: #d6222a;
        }
      }
    }
  }

  &-body {
    margin-top: 20px;
  }

  &-separator {
    &-count {
      &-top {
        width: 100%;
        height: 1px;
        background: #e1e1e1;
        margin-top: 20px;
        margin-bottom: 12px;
      }

      &-bottom {
        width: 100%;
        height: 1px;
        background: #e1e1e1;
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }

    width: 100%;
    height: 1px;
    background: #e1e1e1;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &-response {
    display: flex;

    &-likes {
      display: flex;

      &-icon {
        margin-right: 10px;
        cursor: pointer;

        &-owner {
          margin-right: 10px;
        }
      }
    }

    &-comments {
      margin-left: auto;

      &-event {
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }

  &-create-comment {
    display: flex;

    &-input {
      width: 100%;
      margin-left: 10px;

      &-editor {
        border: 1px solid #e1e1e1;
        margin-bottom: 10px;
      }

      .ant-btn {
        height: 40px;
        width: 96px;
      }
    }

    .ql-toolbar {
      display: none;
    }

    .ql-container {
      font-size: inherit;
      font-family: inherit;
      min-height: 65px;
      height: 100%;
    }

    .ql-tooltip {
      left: -12px !important;
      top: 0px !important;
    }

    .ql-toolbar.ql-snow {
      display: none;
    }

    .ql-editor {
      padding-top: 2px;
    }
  }
}

.comment {
  margin-top: 20px;

  &-child {
    display: flex;
    margin-top: 20px;

    &-wrapper {
      padding: 15px;
      width: 100%;
      background-color: #f1f1f1;
      border: 1px solid #e1e1e1;

      &-header {
        display: flex;

        &-left {
          font-size: 13px;
          font-weight: 700;

          &-time {
            margin-left: 5px;
            font-size: 11px;
            color: rgba(26, 26, 26, 0.5);
          }
        }

        &-right {
          margin-left: auto;

          &-menu {
            width: 130px;

            &-reply {
              color: rgba(26, 26, 26, 0.5);
            }

            &-delete {
              color: #d6222a;
            }
          }
        }
      }

      &-body {
        margin-top: 10px;
      }
    }
  }

  display: flex;

  &-padding {
    padding-left: @gap-sm;
    width: 100%;
  }

  &-create {
    display: flex;
    margin-top: 20px;

    &-input {
      width: 100%;
      margin-left: 10px;

      &-editor {
        border: 1px solid #e1e1e1;
        margin-bottom: 10px;
      }

      .ant-btn {
        height: 40px;
        width: 96px;
      }
    }

    .ql-toolbar {
      display: none;
    }

    .ql-container {
      font-size: inherit;
      font-family: inherit;
      min-height: 65px;
      height: 100%;
    }

    .ql-toolbar.ql-snow {
      display: none;
    }

    .ql-editor {
      padding-top: 2px;
    }

    .ql-tooltip {
      left: -12px !important;
      top: 0px !important;
    }
  }

  &-wrapper {
    padding: 15px;
    width: 100%;
    background-color: #f1f1f1;
    border: 1px solid #e1e1e1;

    &-header {
      display: flex;

      &-left {
        font-size: 13px;
        font-weight: 700;

        &-time {
          margin-left: 5px;
          font-size: 11px;
          color: rgba(26, 26, 26, 0.5);
        }
      }

      &-right {
        margin-left: auto;

        &-menu {
          width: 130px;

          &-reply {
            color: rgba(26, 26, 26, 0.5);
          }

          &-delete {
            color: #d6222a;
          }
        }
      }
    }

    &-body {
      margin-top: 10px;
    }
  }
}

.post-delete-modal {
  .ant-modal-close {
    &-x {
      font-size: 1rem;

      .anticon {
        color: @light-text-50;

        &:hover {
          color: @light-text;
        }
      }
    }
  }

  .post-delete-modal-container {
    padding: 32px;

    &-header {
      text-align: center;

      &-title {
        font-weight: 700;
        font-size: 20px;
        margin-top: 25px;
        margin-bottom: 20px;
      }

      &-text {
        color: rgba(26, 26, 26, 0.5);
        text-align: center;
      }
    }

    &-footer {
      width: 100%;
      text-align: center;

      &-delete {
        margin-right: 20px !important;
      }

      .ant-btn {
        height: 40px;
        width: 100px;
        border-radius: 0px !important;
      }
    }
  }
}

.post-featured-modal {
  .ant-modal-close {
    &-x {
      font-size: 1rem;

      .anticon {
        color: @light-text-50;

        &:hover {
          color: @light-text;
        }
      }
    }
  }

  .post-featured-modal-container {
    &-header {
      text-align: center;

      &-title {
        font-weight: 700;
        font-size: 20px;
        margin-top: 25px;
        margin-bottom: 20px;
      }

      &-text {
        color: rgba(26, 26, 26, 0.5);
        text-align: center;
      }
    }

    &-footer {
      width: 100%;
      text-align: center;
      margin-top: 20px;

      &-delete {
        margin-right: 20px !important;
      }

      .ant-btn {
        height: 40px;
        width: 100px;
        border-radius: 0px !important;
      }
    }
  }
}

.comment-delete-modal {
  .ant-modal-close {
    &-x {
      font-size: 1rem;

      .anticon {
        color: @light-text-50;

        &:hover {
          color: @light-text;
        }
      }
    }
  }

  .comment-delete-modal-container {
    padding: 32px;

    &-header {
      text-align: center;

      &-title {
        font-weight: 700;
        font-size: 20px;
        margin-top: 25px;
        margin-bottom: 20px;
      }

      &-text {
        color: rgba(26, 26, 26, 0.5);
        text-align: center;
      }
    }

    &-footer {
      width: 100%;
      text-align: center;

      &-delete {
        margin-right: 20px !important;
      }

      .ant-btn {
        height: 40px;
        width: 100px;
        border-radius: 0px !important;
      }
    }
  }
}

.post-update-modal {
  .ant-modal-close {
    &-x {
      font-size: 1rem;

      .anticon {
        color: @light-text-50;

        &:hover {
          color: @light-text;
        }
      }
    }
  }

  &-input {
    width: 100%;

    &-editor {
      border: 1px solid #e1e1e1;
      margin-bottom: 24px;
    }

    .ant-btn {
      height: 40px;
      width: 96px;
    }
  }

  .ql-toolbar {
    display: none;
  }

  .ql-container {
    font-size: inherit;
    font-family: inherit;
    min-height: 220px;
    height: 100%;
  }

  .ql-tooltip {
    left: -12px !important;
    top: 0px !important;
  }

  .ql-toolbar.ql-snow {
    display: none;
  }

  .ql-editor {
    padding-top: 2px;
  }

  .ant-btn {
    height: 40px;
    width: 94px;
  }
}
